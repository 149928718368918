import { XMarkIcon } from "@heroicons/react/24/outline";
import BaseModal from "./BaseModal";

type ConfirmationModalProps = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    onCancel: () => void;
    title: string;
    message: string;
}

export default function ConfirmationModal({ open, onClose, onConfirm, onCancel, title, message }: ConfirmationModalProps) {
    return (
        <BaseModal open={open} onClose={onClose}>
            <div className="flex flex-col gap-3">
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold">{title}</h2>
                    <div className="flex items-center gap-2">
                        <XMarkIcon onClick={() => onClose()} className="h-5 w-5 cursor-pointer" />
                    </div>
                </div>
                <h4 className="block text-sm font-light leading-6 text-gray-600">{message}</h4>
                <button onClick={() => onConfirm()} className="bg-indigo-600 hover:bg-indigo-700 rounded-md text-white text-sm font-medium py-1">{"Confirmar"}</button>
                <button onClick={() => onCancel()} className="text-red-600 text-sm font-medium underline hover:no-underline">{"Cancelar"}</button>
            </div>
        </BaseModal>
    )
}
