import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { useBudgetSettings } from "../../hooks/useBudgetSettings";
import { useTransactionCategories } from "../../hooks/useTransactionCategories";
import { createBudgetSetting, updateBudgetSetting } from "../../services/BudgetSettingsService";
import { handleMoneyChange } from "../../utils/handleMoneyChange";
import { formatBrazilianCurrence } from "../../utils/helper";
import { Button } from "../Button";
import { BudgetChart } from "../charts/BudgetChart";
import { CustomInput } from "../CustomInput";
import BudgetSettingModal from "../modals/BudgetSettingModal";
import TransactionCategoryModal from "../modals/TransactionCategoryModal";

export function BudgetSettings() {
    const { setNotification } = useAppContext();
    const [income, setIncome] = useState("");
    const [incomeError, setIncomeError] = useState("");
    const [categoryOpen, setCategoryOpen] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
    const [editCategoryId, setEditCategoryId] = useState<string | null>(null);
    const [createCategoryOpen, setCreateCategoryOpen] = useState(false);
    const [budgetError, setBudgetError] = useState<number>(0);
    const { data: settings } = useBudgetSettings();
    const { data: categories } = useTransactionCategories({
        budget_amount_not_null: true,
        page_size: 50,
    });

    const handleUpdateSettings = async () => {
        try {
            if (income === "") {
                setIncomeError("Preencha o campo com sua renda mensal");
                return;
            }
            if (!settings || (settings && settings?.results.length === 0)) {
                await createBudgetSetting({ base_income: parseFloat(income.replace(",", ".")) });
            } else {
                if (settings?.results[0].id) {
                    await updateBudgetSetting(settings?.results[0].id, { base_income: parseFloat(income.replace(",", ".")) });
                }
            }
            setIncome("");
            setNotification({
                message: "Renda mensal atualizada com sucesso",
                title: "Renda Mensal",
                type: "success"
            })
        } catch (e) {
            setNotification({
                message: "Erro ao atualizar configurações de orçamento",
                title: "Erro",
                type: "error"
            })
        }

    }

    useEffect(() => {
        if (settings && settings?.results?.length > 0) {
            setIncome(`${formatBrazilianCurrence(settings?.results[0].base_income)}`);
        }
    }, [settings])

    // budget error = sum of categories.budget > icome
    const handleBudgetError = () => {
        if (!categories || categories?.results?.length === 0 || !settings || (settings && settings?.results?.length === 0)) {
            setBudgetError(0)
            return;
        }
        const sum = categories?.results?.filter((c) => !!c.budget_amount).reduce((acc, category) => {
            return acc + (!!category.budget_amount ? Number(category.budget_amount) : 0);
        }, 0);
        const result = settings?.results[0].base_income - sum;
        setBudgetError(result);
    }

    useEffect(() => {
        handleBudgetError();
    }, [settings, categories])

    const isUpdated = (settings && settings?.results?.length > 0 && `${formatBrazilianCurrence(settings?.results[0].base_income)}` !== income) || (settings && settings?.results?.length === 0 && income !== "");

    return <>
        <BudgetSettingModal setCreateCategoryOpen={setCreateCategoryOpen} open={categoryOpen} onClose={() => {
            setCategoryOpen(false);
            setSelectedCategoryId(null);
        }} categoryId={selectedCategoryId} onEditCategory={(categoryId: string) => setEditCategoryId(categoryId)} />
        <TransactionCategoryModal open={createCategoryOpen} onClose={() => {
            setCreateCategoryOpen(false)
            setCategoryOpen(true);
            setEditCategoryId(null);
        }} categoryId={editCategoryId} />
        <div className="bg-white shadow sm:rounded-lg p-4">
            <div className="flex gap-2 w-full flex-col">
                <CustomInput title="Renda Mensal"
                    bold
                    fullWidth={false}
                    description="Insira o valor total da sua renda mensal. Não se esqueça de incluir todos os tipos de rendimentos que você recebe regularmente, como salário, bônus, comissões, rendimentos de aluguéis, trabalhos extras, e qualquer outro tipo de renda adicional. É importante que este valor represente a soma de todas as suas fontes de renda para uma análise mais precisa."
                    error={incomeError}
                    onChange={(e) => {
                        handleMoneyChange(e, setIncome)
                        setIncomeError("");
                    }}
                    value={income}
                    placeholder="0.00"
                    fixedPlaceholder="R$"
                    name="goalAmount"
                    id="goal-amount"
                    type="text"
                />
                {isUpdated && <div className="w-full flex justify-center md:justify-end"><Button onClick={() => {
                    handleUpdateSettings();
                }} className="text-white max-w-40 rounded-md text-center" title="Salvar Alterações" /></div>}
            </div>
        </div>
        <div className="bg-white shadow sm:rounded-lg p-4 flex flex-col gap-2">
            <div className="flex flex-row justify-between items-center w-full gap-2">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Distribuição do orçamento</h3>
                <Button
                    className="text-center"
                    title="Adicionar Categoria"
                    onClick={() => {
                        setCategoryOpen(true);
                    }} />
            </div>
            <h4 className="text-sm text-gray-500">
                Cadastre as categorias de seus gastos e definir um orçamento mensal para cada uma. Por exemplo, você pode adicionar categorias como 'Alimentação', 'Transporte', 'Lazer', e especificar o quanto planeja gastar por mês em cada uma delas. Isso ajudará a monitorar e controlar melhor suas finanças.
            </h4>
            {categories?.results?.map((category) => (
                <div className="flex flex-row justify-between items-center w-full cursor-pointer hover:bg-indigo-50 rounded-md ring-1 px-2 py-1 ring-indigo-50" onClick={() => {
                    setCategoryOpen(true);
                    setSelectedCategoryId(category.id)
                }}>
                    <h4 className="block text-sm text-gray-900">{category.name}</h4>
                    <h4 className="block text-sm text-gray-900 font-medium">R${category.budget_amount ? formatBrazilianCurrence(category.budget_amount) : "0,00"}</h4>
                </div>
            ))}
            {Boolean(budgetError && budgetError < 0) && <div className="bg-red-100 text-red-500 px-2 py-1 rounded-md text-sm">
                Seu orçamento atual ultrapassa sua renda mensal em R${formatBrazilianCurrence(Math.abs(budgetError))}, reduza esse valor de alguma categoria para adequar seus gastos
            </div>}
            {Boolean(budgetError && budgetError > 0) && <div className="bg-green-100 text-green-500 px-2 py-1 rounded-md text-sm">
                Você ainda tem R${formatBrazilianCurrence(Math.abs(budgetError))} de sua renda mensal para distribuir em seu orçamento, que tal adicionar esse valor para a reserva de emergência ou investimentos?
            </div>}
            {categories?.results && categories?.results?.length > 0 && <BudgetChart categories={categories.results} />}
        </div>
    </>
}