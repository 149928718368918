/* eslint-disable jsx-a11y/no-redundant-roles */
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';

type BreadCrumbProps = {
    pages: { name: string; href: string; current: boolean }[]
}

export default function BreadCrumb({ pages }: BreadCrumbProps) {
    const navigate = useNavigate();
    return (
        <nav className="flex bg-white rounded-md w-full shadow-sm overflow-hidden truncate" aria-label="Breadcrumb">
            <ol role="list" className="flex items-start sm:items-center flex-col sm:flex-row space-x-0 sm:space-x-4 py-3 px-4">
                <li>
                    <div onClick={() => navigate("/inicio")} className="cursor-pointer flex items-center py-2 sm:py-0">
                        <div className="text-gray-400 cursor-pointer hover:text-gray-500 flex items-center">
                            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <div
                                className="pl-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
                            >
                                Inicio
                            </div>
                            <span className="sr-only">Inicio</span>
                        </div>
                    </div>
                </li>
                {pages.map((page) => (
                    <li key={page.name}>
                        <div className="flex items-center py-2 sm:py-0 flex-grow overflow-hidden truncate">
                            <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            <div
                                onClick={() => navigate(page.href)}
                                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer truncate max-w-max"
                                aria-current={page.current ? 'page' : undefined}
                            >
                                {page.name}
                            </div>
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    )
}
