import { useQuery } from "react-query";
import { CourseFilterParams } from "../models/types";
import { getCourse, getCourses } from "../services/CourseService";
import { QueryKey } from "./state";

export const useCourses = (params: CourseFilterParams) => {
    return useQuery([QueryKey.course, params], async () => {
        const { data } = await getCourses(params);
        return data;
    });
}

export const useCourse = (objectId: string | null) => {
    return useQuery([QueryKey.course, objectId], async () => {
        if (!objectId || (objectId && objectId === "")) return null;
        const { data } = await getCourse(objectId);
        return data;
    });
}