import { twMerge } from "tailwind-merge";
import { EditIcon } from "./Button";
type BaseCardProps = {
    title: string;
    onClick?: () => void;
    icon: React.ReactNode;
    content: React.ReactNode;
    className?: string;
    hideTitle?: boolean;
    hideClick?: boolean;
}

export default function BaseCard({
    icon, title, onClick, className = '', content, hideTitle, hideClick
}: BaseCardProps) {
    const baseCardProps = "flex flex-grow flex-col border border-gray-300 rounded-xl min-w-60 max-w-full items-center"
    return <div className={twMerge(baseCardProps, className)}>
        {!hideTitle && <div className="flex items-center px-6 py-3 gap-3 bg-gray-100 w-full border-b border-gray-300 rounded-t-xl">
            <div className="flex flex-grow flex-row overflow-hidden truncate items-center gap-2">
                {icon}
                <h3 className="truncate text-sm font-semibold leading-7">
                    {title}
                </h3>
            </div>
            {onClick && !hideClick && <EditIcon onClick={() => onClick()} />}
        </div>}
        <div className={`bg-white w-full px-4 py-1 rounded-b-xl flex justify-center flex-grow items-center ${hideTitle ? "rounded-t-xl" : ""}`}>
            {content}
        </div>
    </div>
}