
export default function HelpPage() {

    return <div className="bg-white shadow sm:rounded-lg flex w-full">
        <div className="flex flex-row gap-20 flex-wrap px-4 py-5 sm:p-6 border-b border-gray-200">
            <div className="flex flex-col gap-2 w-80 min-w-80">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Suporte</h2>
                <p className="text-sm leading-6 text-gray-400">
                    Precisa de ajuda? Entre em contato conosco através dos nossos canais de atendimento.
                </p>
            </div>
            <div className="flex flex-row gap-6 flex-grow flex-wrap max-w-[800px] pb-6">
                <div className="flex flex-row gap-2 flex-wrap items-center">
                    <div className="flex flex-grow flex-col gap-2 min-w-72">
                        <label className="text-sm text-gray-500">E-mail</label>
                        <h3 className="text-lg leading-7 text-gray-900">contato@maisumnabolsa.com.br</h3>
                    </div>
                </div>
                <div className="flex flex-row gap-2 flex-wrap items-center">
                    <div className="flex flex-grow flex-col gap-2 min-w-72">
                        <label className="text-sm text-gray-500">WhatsApp</label>
                        <div onClick={() => window.open("https://wa.me/5511993581886?text=Ol%C3%A1%2C%20preciso%20de%20ajuda", "_blank")} className="flex flex-row gap-2 flex-wrap items-center cursor-pointer">
                            <img src="/images/whatsapp.png" alt="whatsapp" width="20px" />
                            <h3 className="text-lg leading-7 text-gray-900">(11) 99358-1886</h3>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
}