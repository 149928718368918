import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { TransactionCategory } from '../../models/types';

type BudgetChartProps = {
    categories: TransactionCategory[];
}

export const BudgetChart = ({ categories }: BudgetChartProps) => {
    const [labels, setLabels] = useState<string[]>(["1", "2", "3"]);
    const [series, setSeries] = useState<number[]>([1, 2, 3]);

    const fetchData = async () => {
        const [categoryLabels, categorySeries] = await Promise.all([
            categories.filter((c) => c.budget_amount !== null).map((c) => c.name || ""),
            categories.filter((c) => c.budget_amount !== null).map((c) => Number(c.budget_amount) || 0),
        ])
        setLabels(categoryLabels);
        setSeries(categorySeries);
    }
    const [legendPosition, setLegendPosition] = useState<'right' | 'bottom'>('right');

    // Handle screen resizing to adjust legend position
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setLegendPosition('bottom');
            } else {
                setLegendPosition('right');
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (categories.length > 0) {
            fetchData();
        } else {
            setLabels([]);
            setSeries([]);
        }
    }, [categories])
    try {
        return (labels.length === 0 || series.length === 0) ? null : <Chart
            key={JSON.stringify(labels)}
            options={{
                chart: {
                    id: 'apexchart-example',
                    toolbar: {
                        show: false
                    },
                },
                dataLabels: {
                    enabled: true,
                },
                labels: labels,
                legend: {
                    position: legendPosition
                }
            }}
            height={250}
            series={series}
            type="pie"
            width={window.innerWidth < 768 ? 324 : 400} />
    } catch (e) {
        console.log(e);
        return null;
    }
}