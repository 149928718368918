import { useNavigate } from "react-router-dom";
import BoxSkeletonLoader from "../skeleton/BoxSkeletonLoader.tsx";
import ThreeDotsLoaderSkeleton from "../skeleton/ThreeDotsLoaderSkeleton";

export default function AIReportSkeletonLoader() {
    const navigate = useNavigate();
    return <div className="bg-white w-full flex-col px-4 py-1 gap-3 pb-4 rounded-b-xl flex justify-center flex-grow items-center">
        <div className="flex flex-row items-start w-full gap-4 pt-3 pb-0">
            <img
                alt=""
                src="/images/ai.png"
                className="h-8 w-8 flex-none rounded-full bg-gray-50"
            />
            <div className="border flex flex-col flex-grow border-gray-300 rounded-md h-full py-2 px-2 gap-1">
                <div className="flex flex-grow justify-between flex-row items-center gap-2">
                    <h4 className="text-xs text-gray-600 font-bold">Alberto <span className="font-light text-gray-600">está carregando sua mensagem...</span></h4>
                    <ThreeDotsLoaderSkeleton />
                </div>
                <div className="flex flex-col gap-1 py-2">
                    <BoxSkeletonLoader height="4" />
                    <BoxSkeletonLoader height="16" />
                </div>
            </div>
        </div>
    </div>
}