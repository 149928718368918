import { CreateAccountData, LoginResponse, User } from "../models/types";
import { httpPostAuthenticated } from "./Api";

const loginEndpoints = {
    login: () => "/central/auth",
    signup: () => "/central/signup"
};

export const getLogin = async (payload: {
    username: string; password: string;
}) => {
    return httpPostAuthenticated<LoginResponse>(loginEndpoints.login(), payload);
}

export const createAccount = async (payload: CreateAccountData) => {
    return httpPostAuthenticated<User>(loginEndpoints.signup(), payload);
}