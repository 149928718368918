import { useQuery } from "react-query";
import { CourseModuleFilterParams } from "../models/types";
import { getCourseModule, getCourseModules } from "../services/CourseModuleService";
import { QueryKey } from "./state";

export const useCourseModules = (params: CourseModuleFilterParams) => {
    return useQuery([QueryKey.courseModule, params], async () => {
        const { data } = await getCourseModules(params);
        return data;
    });
}

export const useCourseModule = (objectId: string | null) => {
    return useQuery([QueryKey.courseModule, objectId], async () => {
        if (!objectId || (objectId && objectId === "")) return null;
        const { data } = await getCourseModule(objectId);
        return data;
    });
}