import { Button } from "./Button";
import { LandingPageFAQ } from "./landing-page/main/LandingPageFAQ";
import { LandingPageFooter } from "./landing-page/main/LandingPageFooter";
import { LandingPageHeader } from "./landing-page/main/LandingPageHeader";
import { LandingPageHeroSection } from "./landing-page/main/LandingPageHeroSection";
import { LandingPagePriceSection } from "./landing-page/main/LandingPagePriceSection";
import LPBenefits from "./landing-page/vsl/LandingPageBenefits";
import OurProcess from "./Process";

export function LandingPage() {
    const ScrollToSection = (sectionId: string) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div className="bg-white">
            <main>
                <LandingPageHeader />
                <LandingPageHeroSection />
                <div id="how-to" className="flex flex-col items-center w-full py-24 px-6 sm:py-32 lg:px-8">
                    <OurProcess />
                    <Button className="text-md mt-8 font-semibold py-2 px-8 max-w-48" title="Assine Agora" onClick={() => ScrollToSection("offer")} />
                </div>
                {/* <LandingPageProblems /> */}
                <LPBenefits />
                {/* <LandingPageFeatures />
                <LandingPagePriceSection />
                <LandingPageFAQ />
                <LandingPageTestimonial /> */}
                <LandingPagePriceSection />
                <LandingPageFAQ />
                <div className="fixed z-50 bottom-6 right-6 cursor-pointer" onClick={() => window.open("https://wa.me/5511993581886?text=Ol%C3%A1,%20tenho%20d%C3%BAvidas%20sobre%20o%20produto", "_blank")}>
                    <img src="/images/wpp.png" alt="whatsapp" className="w-20 h-20 rounded-full" />
                </div>
            </main>

            <LandingPageFooter />

        </div>
    )
}
