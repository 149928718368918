import { ArchiveBoxIcon, PlayCircleIcon, PresentationChartBarIcon, SparklesIcon } from '@heroicons/react/24/outline'

const features = [
    {
        name: 'Inteligência Artificial:',
        description:
            'Nossa inteligência artificial avaliará seu orçamento doméstico para encontrar onde economias podem ser feitas e oferecer sugestões.',
        icon: SparklesIcon,
    },
    {
        name: 'Aulas:',
        description: 'Além do nosso fantástico curso de educação financeira, você terá acesso aos novos cursos disponibilizados todos os meses.',
        icon: PlayCircleIcon,
    },
    {
        name: 'Ferramentas:',
        description: 'Nosso foco é na inovação e construção de ferramentas que facilitem o seu aprendizado e crescimento financeiro, você contará com ferramentas para auxiliar você na gestão de metas, orçamento doméstico e muito mais.',
        icon: PresentationChartBarIcon,
    },
    {
        name: 'Materiais Bônus:',
        description: 'Estamos semanalmente liberando materiais bônus para você, com dicas financeiras, novidades do mercado, ideias de negócio e muito mais.',
        icon: ArchiveBoxIcon,
    },
]

export default function LPBenefits() {
    return (
        <div className="overflow-hidden bg-white py-10 sm:py-10">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="text-base font-semibold leading-7 text-indigo-600">A melhor solução para você</h2>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">O que você vai ter acesso</p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Você terá um ano de acesso em nossa plataforma que oferece uma série de funcionalidades para te ajudar a organizar suas finanças e a atingir seus objetivos financeiros.<br />
                                <br />
                            </p>
                            <img
                                alt="Product screenshot"
                                src="/images/features.png"
                                width={2432}
                                height={1442}
                                className="block lg:hidden w-full md:w-[48rem] max-w-none sm:max-w-full  sm:w-[57rem] md:-ml-4 lg:-ml-0"
                            />
                            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                {features.map((feature) => (
                                    <div key={feature.name} className="relative pl-9">
                                        <dt className="inline font-semibold text-gray-900">
                                            <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-indigo-600" />
                                            {feature.name}
                                        </dt>{' '}
                                        <dd className="inline">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                    <img
                        alt="Product screenshot"
                        src="/images/features.png"
                        width={2432}
                        height={1442}
                        className="hidden lg:block w-full md:w-[48rem] max-w-none sm:max-w-full sm:w-[57rem] md:-ml-4 lg:-ml-0"
                    />
                </div>
            </div>
        </div>
    )
}
