import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { useGoal } from "../../hooks/useGoals";
import { createGoal, deleteGoal, updateGoal } from "../../services/GoalService";
import { handleMoneyChange } from "../../utils/handleMoneyChange";
import { CustomInput } from "../CustomInput";
import BaseModal from "./BaseModal";
import ConfirmationModal from "./ConfirmationModal";

type GoalCreationModalProps = {
    open: boolean;
    onClose: () => void;
    goalId: string | null;
}

export default function GoalCreationModal({ open, onClose, goalId }: GoalCreationModalProps) {
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [amount, setAmount] = useState("");
    const [goalAmount, setGoalAmount] = useState("");
    const [goalAmountError, setGoalAmountError] = useState("");
    const [goalDate, setGoalDate] = useState("");
    const [goalDateError, setGoalDateError] = useState("");
    const { data: goal, isLoading } = useGoal(goalId);
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const { setNotification } = useAppContext();
    useEffect(() => {
        if (goal) {
            setName(goal.name);
            setAmount(`${goal.amount}`.replace(/[.]/g, ","));
            setGoalAmount(`${goal.goal_amount}`.replace(/[.]/g, ","));
            setGoalDate(goal.goal_date);
        } else {
            setName("");
            setAmount("");
            setGoalAmount("");
            setGoalDate("");
        }
    }, [goal, open])

    const handleDeleteGoal = async () => {
        try {
            if (goalId) {
                await deleteGoal(goalId);
                setNotification({
                    type: 'success',
                    message: 'Meta excluida com sucesso',
                    title: 'Meta excluida'
                })
                onClose();
                setOpenConfirmation(false);
            } else {
                setNotification({
                    type: 'error',
                    message: 'Nenhuma meta selecionada',
                    title: 'Erro ao excluir meta'
                })
            }
        } catch (error) {
            setNotification({
                type: 'error',
                message: 'Tente novamente',
                title: 'Erro ao excluir meta'
            })
        }
    }

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (name === "") {
                return setNameError("Informe um nome para a meta")
            }
            if (goalAmount === "") {
                return setGoalAmountError("Informe o valor da meta")
            }
            if (goalDate === "") {
                return setGoalDateError("Informe a data da meta")
            }
            if (goalId) {
                await updateGoal(goalId, {
                    name,
                    amount: Number(amount.replace(/[,]/g, ".")),
                    goal_amount: goalAmount === "" ? 0 : Number(goalAmount.replace(/[,]/g, ".")),
                    goal_date: goalDate,
                })
            } else {
                await createGoal({
                    name,
                    amount: Number(amount.replace(/[,]/g, ".")),
                    goal_amount: goalAmount === "" ? 0 : Number(goalAmount.replace(/[,]/g, ".")),
                    goal_date: goalDate,
                })
            }
            onClose();
            setNotification({
                type: 'success',
                message: `Meta ${goalId ? "editada" : "adicionada"} com sucesso`,
                title: `Meta`
            })
        } catch (error) {
            setNotification({
                type: 'error',
                message: `Erro ao ${goalId ? "editar" : "adicionar"} a meta, tente novamente`,
                title: `Meta`
            })
        } finally {
            setLoading(false);
        }
    }

    return (
        <BaseModal open={open} onClose={onClose}>
            <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold">{goalId ? "Editar Meta" : "Criar Meta"}</h2>
                    <div className="flex items-center gap-2">
                        {goalId && <span onClick={() => setOpenConfirmation(true)} className={`cursor-pointer inline-flex items-center bg-red-300 gap-x-1.5 rounded-md px-2 py-1 text-xs font-light text-red-900 ring-1 ring-inset ring-gray-200 hover:ring-gray-300 hover:bg-red-400 hover:text-white`}>
                            Excluir
                        </span>}
                        <XMarkIcon onClick={() => onClose()} className="h-5 w-5 cursor-pointer" />
                    </div>
                </div>
                <CustomInput title="Nome"
                    description="Informe um nome para sua meta"
                    error={nameError}
                    onChange={(e) => {
                        setName(e.target.value)
                        setNameError("");
                    }}
                    value={name}
                    placeholder="Nome da meta"
                    name="meta"
                    id="meta-name"
                    type="text"
                />
                <CustomInput title="Valor"
                    description="Informe o valor da meta"
                    error={goalAmountError}
                    onChange={(e) => {
                        handleMoneyChange(e, setGoalAmount)
                        setGoalAmountError("");
                    }}
                    value={goalAmount}
                    placeholder="0.00"
                    fixedPlaceholder="R$"
                    name="goalAmount"
                    id="goal-amount"
                    type="text"
                />
                <CustomInput title="Acumulado"
                    description="Informe o valor que já possuí acumulado da meta, deixe vazio caso esteja começando agora"
                    error={""}
                    onChange={(e) => {
                        handleMoneyChange(e, setAmount)
                        setGoalAmountError("");
                    }}
                    value={amount}
                    placeholder="0.00"
                    fixedPlaceholder="R$"
                    name="amount"
                    id="amount"
                    type="text"
                />
                <CustomInput title="Data"
                    description="Informe a data que deseja atingir a meta"
                    error={goalDateError}
                    onChange={(e) => {
                        setGoalDate(e.target.value)
                        setGoalDateError("");
                    }}
                    value={goalDate}
                    placeholder="dd/mm/yyyy"
                    name="amount"
                    id="amount"
                    type="date"
                />
                <button disabled={loading} onClick={() => {
                    handleSubmit();
                }} className="bg-indigo-600 hover:bg-indigo-700 rounded-md text-white text-sm font-medium py-1">{goalId ? "Salvar Alterações" : "Criar Meta"}</button>
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    onConfirm={() => handleDeleteGoal()}
                    onCancel={() => setOpenConfirmation(false)}
                    title="Excluir Meta"
                    message={`Deseja realmente excluir a meta ${goal?.name}`} />
            </div>
        </BaseModal>
    )
}
