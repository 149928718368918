import { twMerge } from 'tailwind-merge';

export const GoalCardSkeletonLoader = ({ className = '' }) => {
    const baseCardProps = "flex flex-grow flex-col border border-gray-300 rounded-xl items-center max-w-80 min-w-72 min-h-60";

    return (
        <div className={twMerge(baseCardProps, className)}>
            {/* Skeleton for the title section */}
            <div className="flex items-center px-6 py-3 gap-3 bg-gray-100 w-full border-b border-gray-300 rounded-t-xl animate-pulse">
                <div className="flex flex-grow flex-row overflow-hidden truncate items-center gap-2">
                    {/* Skeleton for the icon */}
                    <div className="w-6 h-6 bg-gray-200 rounded-full"></div>

                    {/* Skeleton for the title */}
                    <div className="h-4 w-24 bg-gray-200 rounded-md"></div>
                </div>
                {/* Skeleton for the edit icon */}
                <div className="w-6 h-6 bg-gray-200 rounded-md"></div>
            </div>

            {/* Skeleton for the content */}
            <div className="bg-white w-full px-4 py-1 rounded-b-xl flex justify-center flex-grow items-center animate-pulse">
                <div className="flex flex-col items-center justify-center gap-2">
                    {/* Skeleton block for content */}
                    <div className="w-36 h-6 bg-gray-200 rounded-md"></div>
                    <div className="w-60 h-4 bg-gray-200 rounded-md"></div>
                </div>
            </div>
        </div>
    );
};

export const GoalCardListSkeletonLoader = () => {
    return <div className="flex flex-row gap-6 flex-wrap">
        <GoalCardSkeletonLoader />
        <GoalCardSkeletonLoader />
        <GoalCardSkeletonLoader />
        <GoalCardSkeletonLoader />
        <GoalCardSkeletonLoader />
        <GoalCardSkeletonLoader />
        <GoalCardSkeletonLoader />
        <GoalCardSkeletonLoader />
    </div>
}