import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseBadge } from "../components/Badge";
import CourseModulesClassesTable from "../components/CourseModulesClasses";
import LoadingSpinner from "../components/Loading";
import CourseModuleCreationModal from "../components/modals/CourseModuleCreationModal";
import { useCourseModule } from "../hooks/useCourseModules";
import { useCourse } from "../hooks/useCourses";

export default function AdminCourseModulePage() {
    const navigate = useNavigate()
    const { courseId, moduleId } = useParams();
    if (!courseId || !moduleId) {
        navigate("/admin")
    }
    const { data: course } = useCourse(courseId ?? "");
    const { data: courseModule } = useCourseModule(moduleId ?? "");
    const [openCourse, setOpenCourse] = useState<boolean>(false);
    // const { data: modules } = useCourseModule
    return !course || !courseId || !courseModule || !moduleId ? <LoadingSpinner /> : <div className="bg-white shadow sm:rounded-lg">
        <CourseModuleCreationModal courseId={courseId} moduleId={moduleId} onClose={() => setOpenCourse(false)} open={openCourse} />
        <div className="px-4 py-5 sm:p-6">
            <div className="flex flex-col gap-2">
                <BaseBadge title={course.title} />
                <div className="flex flex-row gap-2 items-center justify-between">
                    <div className="flex flex-row gap-2 items-center">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Módulo-{courseModule.module_number}: {courseModule.title}</h3>
                    </div>
                    <PencilSquareIcon onClick={() => setOpenCourse(true)} className="h-5 w-5 flex-shrink-0 text-indigo-500 cursor-pointer" />
                </div>
                <div className="max-w-xl text-sm text-gray-500">
                    <p>
                        {courseModule.description}
                    </p>
                </div>
            </div>
            <CourseModulesClassesTable moduleId={moduleId} />
        </div>
    </div>
}