import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { useMonthlyTransaction } from "../../hooks/useMonthlyTransactions";
import { useTransactionCategories } from "../../hooks/useTransactionCategories";
import { TransactionCategory, TransactionCategoryFilterParams } from "../../models/types";
import { createMonthlyTransaction, deleteMonthlyTransaction, updateMonthlyTransaction } from "../../services/MonthlyTransactionService";
import { handleMoneyChange } from "../../utils/handleMoneyChange";
import { CategoryBadge } from "../Badge";
import { CustomInput } from "../CustomInput";
import CustomSelectDropdown from "../monthly-budget/CustomSelectDropdown";
import BaseModal from "./BaseModal";
import ConfirmationModal from "./ConfirmationModal";

type BaseModalProps = {
    open: boolean;
    onClose: () => void;
    transactionId: string | null;
}

export default function MonthlyTransactionModal({ open, onClose, transactionId }: BaseModalProps) {
    const [selectedCategory, setSelectedCategory] = useState<TransactionCategory | null>(null);
    const [name, setName] = useState<string>("");
    const [amount, setAmount] = useState<string>("0");
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [amountError, setAmountError] = useState<string>("");
    const [paid, setPaid] = useState<boolean>(false);
    const [dueDate, setDueDate] = useState<string>("");
    const [paymentDate, setPaymentDate] = useState<string>("");

    const { setNotification } = useAppContext();

    const { data: transaction } = useMonthlyTransaction(transactionId);

    useEffect(() => {
        if (transactionId && transaction) {
            setName(transaction.name)
            setAmount(`${transaction.amount}`.replace(".", ","));
            setSelectedCategory(transaction.category_name)
            setPaid(transaction.paid)
            setDueDate(transaction.month)
            setPaymentDate(transaction.payment_date)
        } else {
            setName("")
            setAmount("0");
            setSelectedCategory(null)
            setPaid(false)
            setDueDate("")
            setPaymentDate("")
        }
    }, [open, transaction])

    const handleCreate = async () => {
        try {
            if (transactionId) {
                await updateMonthlyTransaction(transactionId, {
                    amount: parseFloat(amount.replace(",", ".")),
                    category: selectedCategory!.id,
                    name,
                    paid,
                    payment_date: paymentDate !== "" ? paymentDate : null,
                    month: dueDate,
                })
            } else {
                await createMonthlyTransaction({
                    amount: parseFloat(amount.replace(",", ".")),
                    category: selectedCategory!.id,
                    name,
                    paid,
                    payment_date: paymentDate !== "" ? paymentDate : null,
                    month: dueDate,
                })
            }
            setNotification({
                type: 'success',
                message: `Transação ${transactionId ? "editada" : "adicionada"} com sucesso`,
                title: `Transação ${transactionId ? "editada" : "adicionada"}`
            })
            onClose();
        } catch (error) {
            setNotification({
                type: 'error',
                message: 'Tente novamente',
                title: 'Erro ao adicionar transação'
            })
        }
    }

    const handleDeleteTransaction = async () => {
        try {
            if (transactionId) {
                await deleteMonthlyTransaction(transactionId);
                setNotification({
                    type: 'success',
                    message: 'Transação excluida com sucesso',
                    title: 'Transação excluida'
                })
                onClose();
                setOpenConfirmation(false);
            } else {
                setNotification({
                    type: 'error',
                    message: 'Nenhuma transação selecionada',
                    title: 'Erro ao excluir transação'
                })
            }
        } catch (error) {
            setNotification({
                type: 'error',
                message: 'Tente novamente',
                title: 'Erro ao excluir transação'
            })
        }
    }

    return (
        <BaseModal open={open} onClose={onClose}>
            <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">

                    <h2 className="text-lg font-semibold">{transactionId ? "Editar" : "Adicionar"} Transação</h2>
                    <div className="flex items-center gap-2">
                        {transactionId && <span onClick={() => setOpenConfirmation(true)} className={`cursor-pointer inline-flex items-center bg-red-300 gap-x-1.5 rounded-md px-2 py-1 text-xs font-light text-red-900 ring-1 ring-inset ring-gray-200 hover:ring-gray-300 hover:bg-red-400 hover:text-white`}>
                            Excluir
                        </span>}
                        <XMarkIcon onClick={() => onClose()} className="h-5 w-5 cursor-pointer" />
                    </div>
                </div>
                <div className="flex flex-col">
                    <h4 className="block text-sm font-medium text-gray-900">Categoria</h4>
                    <h5 className="text-sm text-gray-500">Selecione a categoria que melhor descreve essa transação</h5>
                </div>
                <CustomSelectDropdown<TransactionCategory, TransactionCategoryFilterParams>
                    params={{ budget_amount_not_null: true, page_size: 100 }}
                    secondComponent={(category) => <CategoryBadge category={category} />}
                    useGetOptions={useTransactionCategories} nameKey="name" onChange={setSelectedCategory} selected={selectedCategory} />
                <div className="flex flex-col">
                    <h4 className="block text-sm font-medium text-gray-900">Nome da transação</h4>
                    <h5 className="text-sm text-gray-500">Insira um nome de identificação para transação</h5>
                </div>
                <input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="flex flex-col">
                    <h4 className="block text-sm font-medium text-gray-900">Valor</h4>
                    <h5 className="text-sm text-gray-500">Informe o valor da transação</h5>
                </div>
                <CustomInput
                    bold
                    error={amountError}
                    onChange={(e) => {
                        handleMoneyChange(e, setAmount)
                        setAmountError("");
                    }}
                    value={amount}
                    placeholder="0.00"
                    fixedPlaceholder="R$"
                    id="valor"
                    type="text"
                />
                <CustomInput title="Data de vencimento"
                    description="Informe a data de vencimento da transação"
                    error={""}
                    onChange={(e) => {
                        setDueDate(e.target.value)
                        // setGoalDateError("");
                    }}
                    value={dueDate}
                    placeholder="dd/mm/yyyy"
                    name="amount"
                    id="amount"
                    type="date"
                />
                <CustomInput title="Data de pagamento"
                    description="Informe a data de pagamento da transação"
                    error={""}
                    onChange={(e) => {
                        setPaymentDate(e.target.value)
                        // setGoalDateError("");
                    }}
                    value={paymentDate}
                    placeholder="dd/mm/yyyy"
                    name="amount"
                    id="amount"
                    type="date"
                />
                <button onClick={() => handleCreate()} className="bg-indigo-600 rounded-md text-white font-medium py-1">{transactionId ? "Atualizar" : "Adicionar"}</button>
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    onConfirm={() => handleDeleteTransaction()}
                    onCancel={() => setOpenConfirmation(false)}
                    title="Excluir Transação"
                    message={`Deseja realmente excluir a transação ${transaction?.name}`} />
            </div>
        </BaseModal >
    )
}
