import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";

type ThreeDotsMenuProps = {
    items: {
        name: string;
        description: string;
        icon: React.ElementType;
        onClick: () => void;
    }[];
    className?: string;

}

export default function ThreeDotsMenu({ items, className }: ThreeDotsMenuProps) {
    return <Popover className={twMerge("relative", className ?? "")}>
        {({ close }) => (
            <>
                <PopoverButton className="inline-flex justify-end items-end gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                    <EllipsisVerticalIcon aria-hidden="true" className="h-6 w-6" />
                </PopoverButton>

                <PopoverPanel
                    className="absolute right-full z-10 flex w-screen max-w-max transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                >
                    <div className="w-screen max-w-xs md:max-w-md flex-auto overflow-hidden rounded-xl bg-white text-sm leading-6 shadow-lg ring-2 ring-gray-900/5">
                        <div className="">
                            {items.map((item) => (
                                <div key={item.name} className="group cursor-pointer relative flex gap-x-3 p-2 rounded-lg hover:bg-gray-50">
                                    <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                        <item.icon aria-hidden="true" className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" />
                                    </div>
                                    <div>
                                        <div onClick={() => {
                                            item.onClick();
                                            close();
                                        }} className="font-semibold text-gray-900">
                                            {item.name}
                                            <span className="absolute inset-0" />
                                        </div>
                                        <p className="mt-1 text-gray-600">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </PopoverPanel>
            </>
        )}
    </Popover>
}