import queryClient, { QueryKey } from "../hooks/state";
import { BudgetSetting } from "../models/types";
import { PaginatedResponse } from './../models/types';
import { httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./Api";

const endpoints = {
  list: () => "/central/central/budget_settings",
  retrieve: (id: string) => `/central/central/budget_settings/${id}`,
};

export const getBudgetSetting = async (objectId: string) => {
  return httpGetAuthenticated<BudgetSetting>(endpoints.retrieve(objectId));
}

export const getBudgetSettings = async () => {
  return httpGetAuthenticated<PaginatedResponse<BudgetSetting>>(endpoints.list());
}

export const createBudgetSetting = async (payload: BudgetSetting) => {
  const response = await httpPostAuthenticated<BudgetSetting>(endpoints.list(), payload);
  queryClient.invalidateQueries(QueryKey.budgetSettings);
  return response.data;
}

export const updateBudgetSetting = async (id: string, payload: BudgetSetting) => {
  const response = await httpPatchAuthenticated(endpoints.retrieve(id), payload);
  queryClient.invalidateQueries(QueryKey.budgetSettings);
  return response.data;
}