import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useTransactionCategories } from "../hooks/useTransactionCategories";
import { BudgetChart } from "./charts/BudgetChart";

export default function BudgetSummary() {
    const { data: categories } = useTransactionCategories({
        budget_amount_not_null: true,
        page_size: 50,
    });
    const navigate = useNavigate();
    return <div className="flex flex-col border border-gray-300 rounded-xl w-full sm:w-1/2 flex-grow items-center">
        <div className="flex items-center px-6 py-3 gap-3 bg-gray-100 w-full border-b border-gray-300 rounded-t-xl">
            <CalendarDaysIcon className="h-6 w-6 text-indigo-700" />
            <h3 className="text-sm font-semibold leading-7">
                Orçamento Mensal
            </h3>
        </div>
        <div className="flex h-full max-w-max overflow-hidden bg-white w-full px-4 py-1 rounded-b-xl items-center justify-center">
            {categories?.results?.length === 0 && <div className="flex py-5 flex-col items-center justify-center gap-1">
                <h3 className="font-semibold text-sm">Não configurado</h3>
                <h3 className="text-sm text-gray-600">Configure seu orçamento mensal</h3>
                <h4 onClick={() => navigate("/orcamento")} className="mt-2 text-sm cursor-pointer hover:bg-indigo-800 text-white py-1 px-4 rounded-md bg-indigo-700">Configurar</h4>
            </div>}
            {categories?.results && categories?.results?.length > 0 && <BudgetChart categories={categories.results} />}
        </div>
    </div>
}