import { SparklesIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import queryClient, { QueryKey } from "../hooks/state";
import { useAISummary } from "../hooks/useAI";
import AIReportAvailable from "./alberto/ReportAvailable";
import AIReportGenerating from "./alberto/ReportGenerating";
import AIReportReady from "./alberto/ReportReady";
import AIReportSkeletonLoader from "./alberto/ReportSkeletonLoader";
import AIReportUnavailable from "./alberto/ReportUnavailable";

export default function AISummary() {
    const { data: summary, isLoading } = useAISummary();

    useEffect(() => {
        const interval = setInterval(() => {
            if (summary?.status === 'generating' || summary?.status === 'regenerating') {
                queryClient.invalidateQueries(QueryKey.aiSummary);
            } else {
                clearInterval(interval);
            }
        }, 10000);

        return () => clearInterval(interval);
    }, [summary, queryClient]);

    return <div className="flex flex-col border border-gray-300 rounded-xl w-full sm:w-1/2 flex-grow items-center">
        <div className="flex items-center px-6 py-3 gap-3 bg-gray-100 w-full border-b border-gray-300 rounded-t-xl">
            <SparklesIcon className="h-6 w-6 text-indigo-700" />
            <h3 className="text-sm font-semibold leading-7">
                Relatório da Inteligência Artificial
            </h3>
        </div>
        {isLoading && <AIReportSkeletonLoader />}
        {!isLoading && <>
            {summary?.status === "pending_settings" && <AIReportUnavailable />}
            {summary?.allow_generate && summary?.status === "pending_generate" && <AIReportAvailable />}
            {(summary?.status === "generating" || summary?.status === "regenerating") && <AIReportGenerating />}
            {summary?.status === "generated" && <AIReportReady />}
        </>}
    </div>
}