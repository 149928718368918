import { useQuery } from "react-query";
import { GoalFilterParams } from "../models/types";
import { getGoal, getGoals } from "../services/GoalService";
import { QueryKey } from "./state";

export const useGoal = (objectId: string | null) => {
    return useQuery([QueryKey.goals, objectId], async () => {
        if (!objectId || (objectId && objectId === "")) return null;
        const { data } = await getGoal(objectId);
        return data;
    });
}

export const useGoals = (params: GoalFilterParams) => {
    return useQuery([QueryKey.goals, params], async () => {
        const { data } = await getGoals(params);
        return data;
    });
}