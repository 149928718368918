import CourseCard from "../components/CourseCard";
import PaginatedCard from "../components/Pagination";
import { useCourses } from "../hooks/useCourses";
import { Course, CourseFilterParams } from "../models/types";

export default function CoursesPage() {
    return <div className="bg-white shadow sm:rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:p-6 max-w-full">
            <div className="flex flex-col gap-4">
                <h3 className="text-md font-semibold text-gray-900">Cursos em andamento</h3>
                <div className="text-sm text-gray-500">
                    Continue de onde parou! Aqui encontra os cursos que você já começou e que ainda não terminou. Aproveite para revisar o conteúdo e completar o curso.
                </div>
                <div className="flex w-full max-w-full flex-wrap gap-4">
                    <PaginatedCard<Course, CourseFilterParams>
                        useGetData={useCourses}
                        pageSize={5}
                        emptyComponent={<div className="text-gray-500 text-sm">Você ainda não iniciou nenhum curso.</div>}
                        params={{ started: true }}
                        render={(course) => <CourseCard key={course.id} course={course} />} />
                </div>
                <h3 className="text-md font-semibold text-gray-900">Todos os cursos</h3>
                <div className="text-sm text-gray-500">
                    Aqui estão nossos cursos cuidadosamente desenvolvidos para ajudar você a dominar o universo das finanças pessoais. Se você está começando a aprender sobre gestão financeira ou já tem experiência e deseja aprofundar seus conhecimentos, nossos cursos oferecem abordagens práticas e acessíveis. Desde conceitos básicos até estratégias avançadas, para ajudar você a tomar decisões financeiras mais conscientes e seguras.
                </div>
                <div className="flex w-full max-w-full flex-wrap gap-4">
                    <PaginatedCard<Course, CourseFilterParams>
                        useGetData={useCourses}
                        pageSize={5}
                        params={{ started: false }}
                        render={(course) => <CourseCard key={course.id} course={course} />} />
                </div>
            </div>
        </div>
    </div>
}