import { InformationCircleIcon, SparklesIcon, TableCellsIcon } from '@heroicons/react/24/outline'

const supportLinks = [
    {
        name: 'Inovador',
        description:
            'Você vai aprender a dominar suas finanças com uma plataforma que une o que há de mais moderno em ferramentas tecnológicas e conhecimento prático. Tudo isso de forma simples, sem complicação, e ao alcance das suas mãos. Essa é a chance de transformar sua vida financeira!',
        icon: SparklesIcon,
    },
    {
        name: 'Chega de planilhas',
        description:
            'Excel é coisa do passado! Nossa plataforma utiliza tecnologias fáceis e intuitivas, projetadas para que você organize suas finanças sem precisar lidar com planilhas complexas e nem excel.',
        icon: TableCellsIcon,
    },
    {
        name: 'Suporte personalizado',
        description:
            'Está se sentindo sobrecarregado com suas finanças? Não sabe por onde começar ou a quem recorrer? Nossa plataforma possui aulas e ferramentas para te ajudar. Receba orientação personalizada, tire suas dúvidas e comece a trilhar o caminho para a estabilidade financeira.',
        icon: InformationCircleIcon,
    },
]

export default function LandingPageCards() {
    return (
        <div className="bg-white">
            {/* Header */}
            <div className="relative bg-gray-800 pb-32">
                <div className="absolute inset-0">
                    <img
                        alt=""
                        src="/images/bg-lp.jpeg"
                        className="h-full w-full object-cover"
                    />
                    <div aria-hidden="true" className="absolute inset-0 bg-gray-800 mix-blend-multiply" />
                </div>
                <div className="relative mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
                    <h1 className="text-4xl font-bold tracking-tight text-white md:text-5xl lg:text-6xl">O que você vai aprender</h1>
                    <p className="mt-6 max-w-3xl text-xl text-gray-300">
                        Passo a passo, como criar um orçamento eficiente, eliminar dívidas, atingir suas metas e construir uma aposentadoria para  <span className="text-indigo-600">garantir seu futuro!</span>
                    </p>
                </div>
            </div>

            {/* Overlapping cards */}
            <section aria-labelledby="contact-heading" className="relative z-10 mx-auto -mt-32 max-w-7xl px-6 pb-10 lg:px-8">
                <h2 id="contact-heading" className="sr-only">
                    Contact us
                </h2>
                <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-0">
                    {supportLinks.map((link) => (
                        <div key={link.name} className="flex flex-col rounded-2xl bg-white shadow-xl">
                            <div className="relative flex-1 px-6 pb-8 pt-16 md:px-8">
                                <div className="absolute top-0 inline-block -translate-y-1/2 transform rounded-xl bg-indigo-600 p-5 shadow-lg">
                                    <link.icon aria-hidden="true" className="h-6 w-6 text-white" />
                                </div>
                                <h3 className="text-xl font-medium text-gray-900">{link.name}</h3>
                                <p className="mt-4 text-base text-gray-500">{link.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    )
}
