import { useEffect, useState } from "react";
import { useUser } from "../hooks/useUsers";

export default function Profile() {
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [currentPassword, setCurrentPassword] = useState<string>("");

    const { data: user } = useUser();

    useEffect(() => {
        setFirstName(user?.first_name || "");
        setLastName(user?.last_name || "");
    }, [user]);

    const isUpdated = firstName !== user?.first_name || lastName !== user?.last_name;

    return <div className="bg-white shadow sm:rounded-lg">
        {/* <div className="flex flex-row gap-20 flex-wrap px-4 py-5 sm:p-6 border-b border-gray-200"> */}
        <div className="flex flex-row gap-20 flex-wrap px-4 py-5 sm:p-6">
            <div className="flex flex-col gap-2 w-80 min-w-80">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações Pessoais</h2>
                <p className="text-sm leading-6 text-gray-400">
                    Atualize os dados do seu perfil.
                </p>
            </div>
            <div className="flex flex-col gap-6 flex-grow max-w-[800px] pb-6">
                <div className="flex flex-row gap-6 flex-wrap">
                    <div className="flex flex-grow flex-col gap-2 min-w-72">
                        <label className="text-sm text-gray-500">Primeiro nome</label>
                        <input type="text" disabled value={firstName} onChange={(e) => setFirstName(e.target.value)} className="w-full border border-gray-200 rounded-md px-3 py-2" />
                    </div>
                    <div className="flex flex-grow flex-col gap-2 min-w-72">
                        <label className="text-sm text-gray-500">Sobrenome</label>
                        <input type="text" disabled value={lastName} onChange={(e) => setLastName(e.target.value)} className="w-full border border-gray-200 rounded-md px-3 py-2" />
                    </div>
                </div>
                <div className="flex flex-grow flex-col gap-2 min-w-72">
                    <label className="text-sm text-gray-500">E-mail</label>
                    <input disabled={true} value={user?.email} type="text" className="w-full border border-gray-200 text-gray-300 rounded-md px-3 py-2" />
                </div>
                <div className="w-full flex justify-end">
                    {/* <button disabled={!isUpdated} className={`${!isUpdated ? "bg-gray-400" : "bg-indigo-700"} rounded-md text-white text-sm py-2 px-4 max-w-24`}>Salvar</button> */}
                </div>
            </div>
        </div>
        {/* <div className="flex flex-row gap-20 flex-wrap px-4 py-5 sm:p-6 border-b border-gray-200">
            <div className="flex flex-col gap-2 w-80 min-w-80">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Alterar Senha</h2>
                <p className="text-sm leading-6 text-gray-400">
                    Aqui você consegue atualizar sua senha da sua conta
                </p>
            </div>
            <div className="flex flex-col gap-6 flex-grow max-w-[800px]">
                <div className="flex flex-grow flex-col gap-2 min-w-72">
                    <label className="text-sm text-gray-500">Senha atual</label>
                    <input value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} type="password" className="w-full border border-gray-200 rounded-md px-3 py-2" />
                </div>
                <div className="flex flex-grow flex-col gap-2 min-w-72">
                    <label className="text-sm text-gray-500">Nova senha</label>
                    <input value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} type="password" className="w-full border border-gray-200 rounded-md px-3 py-2" />
                </div>
                <div className="flex flex-grow flex-col gap-2 min-w-72">
                    <label className="text-sm text-gray-500">Repetir nova senha</label>
                    <input value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} type="password" className="w-full border border-gray-200 rounded-md px-3 py-2" />
                </div>
                <div className="w-full flex justify-end">
                    <button className="bg-indigo-700 rounded-md text-white text-sm py-2 px-4 max-w-24">Salvar</button>
                </div>
            </div>
        </div> */}
    </div>
}