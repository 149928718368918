import { useNavigate } from "react-router-dom";
import { Button } from "../Button";

export default function AIReportReady() {
    const navigate = useNavigate();
    return <div className="bg-white w-full flex-col px-4 py-1 gap-3 pb-4 rounded-b-xl flex justify-center flex-grow items-center">
        <div className="flex flex-row items-start w-full gap-4 pt-3 pb-0">
            <img
                alt=""
                src="/images/ai.png"
                className="h-8 w-8 flex-none rounded-full bg-gray-50"
            />
            <div className="border flex flex-col border-gray-300 rounded-md h-full py-2 px-2 gap-1">
                <h4 className="text-xs text-gray-600 font-bold">Alberto <span className="font-light text-gray-600">enviou:</span></h4>
                <h4 className="text-xs text-gray-900 font-light">
                    <div className="flex flex-col gap-1 py-2">
                        <p className="text-xs text-gray-900 font-semibold">Relatório finalizado!</p>
                        <p className="text-xs text-gray-900 font-light">Foi mamão com açucar! Espero que as dicas que eu preparei sejam valiosas para você, e não se esqueça, qualquer dúvida que tiver você pode falar com nosso time de suporte clicando no menu Suporte e no número de WhatsApp que aparece, nossa missão é ajudar você a organizar sua vida financeira. <br /><br />Para acessar o relatório, só clicar no botão abaixo. </p>
                    </div>
                </h4>
            </div>
        </div>
        <Button onClick={() => navigate("/sugestoes-alberto")} title="Visualizar Relatório" />
    </div>
}