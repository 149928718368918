import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Course, CourseModule, CourseModuleClass, TableColumn } from "../../models/types";

export const coursesColumns: TableColumn<Course>[] = [
    {
        field: "title",
        header: "Titulo",
        render: (t) => <h4>{t.title}</h4>,
    },
    {
        field: "visible",
        header: "Visivel",
        render: (t) => t.visible ? <CheckIcon className="h-5 w-5 flex-shrink-0 text-green-600" /> : <XMarkIcon className="h-5 w-5 flex-shrink-0 text-red-600" />,
    },
]

export const moduleColumns: TableColumn<CourseModule>[] = [
    {
        field: "title",
        header: "Titulo",
        render: (t) => <h4>{t.title}</h4>,
    },
    {
        field: "description",
        header: "Descrição",
        render: (t) => <h4>{t.description}</h4>,
    },
    {
        field: "module_number",
        header: "Número do módulo",
        render: (t) => <h4>{t.module_number}</h4>,
    },
]

export const moduleClassColumns: TableColumn<CourseModuleClass>[] = [
    {
        field: "title",
        header: "Titulo",
        render: (t) => <h4>{t.title}</h4>,
    },
    {
        field: "description",
        header: "Descrição",
        render: (t) => <h4>{t.description}</h4>,
    },
    {
        field: "class_number",
        header: "Número da aula",
        render: (t) => <h4>{t.class_number}</h4>,
    },
]