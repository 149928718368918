import { useNavigate } from "react-router-dom";
import ThreeDotsLoaderSkeleton from "../skeleton/ThreeDotsLoaderSkeleton";

export default function AIReportGenerating() {
    const navigate = useNavigate();
    return <div className="bg-white w-full flex-col px-4 py-1 gap-3 pb-4 rounded-b-xl flex justify-center flex-grow items-center">
        <div className="flex flex-row items-start w-full gap-4 pt-3 pb-0">
            <img
                alt=""
                src="/images/ai.png"
                className="h-8 w-8 flex-none rounded-full bg-gray-50"
            />
            <div className="border flex flex-col border-gray-300 rounded-md h-full py-2 px-2 gap-1">
                <div className="flex flex-grow justify-between flex-row items-center gap-2">
                    <h4 className="text-xs text-gray-600 font-bold">Alberto <span className="font-light text-gray-600">enviou:</span></h4>
                </div>
                <h4 className="text-xs text-gray-900 font-light">
                    <div className="flex flex-col gap-1 py-2">
                        <p className="text-xs text-gray-900 font-semibold">Estou trabalhando nisso!</p>
                        <p className="text-xs text-gray-900 font-light">Estou preparando seu relatório e isso pode levar alguns instantes, então fique tranquilo, assim que ele estiver pronto você vai ver um botão aqui para acessa-lo.</p>
                    </div>
                </h4>
                <div className="flex flex-grow pl-1 pb-1">
                    <ThreeDotsLoaderSkeleton />
                </div>
            </div>
        </div>
    </div>
}