export const setCookie = (cname: string, cvalue: string) => {
    document.cookie = cname + "=" + cvalue + `;path=/;`;
  };
  
  export const getCookie = (cname: string) => {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = ca.length - 1; i >= 0; i--) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
  