import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import LoadingSpinner from "./Loading";

type ButtonProps = {
    title: string;
    onClick: () => void;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
}

export function Button({ title, onClick, disabled, className = '', loading }: ButtonProps) {
    const baseProps = "text-sm text-center cursor-pointer hover:bg-indigo-800 text-white py-1 px-4 rounded-md bg-indigo-700"
    return <h4 onClick={() => !disabled && onClick()} className={twMerge(baseProps, className)}>
        {loading && <LoadingSpinner />}
        {!loading && title}
    </h4>
}

export function EditIcon({ onClick, className = '', }: { onClick: () => void; className?: string }) {
    const baseProps = "h-5 w-5 min-w-5 min-h-5 text-indigo-700 cursor-pointer hover:text-indigo-800"
    return <PencilSquareIcon onClick={() => onClick()} className={twMerge(baseProps, className)} />
}