import { useAdminUsers } from "../hooks/useUsers";
import { usersColumns } from "../pages/shared/UsersTable";
import BaseTable from "./monthly-budget/Table";

export default function UsersAdmin() {
    return <>
        <BaseTable
            title={null}
            onRowClick={(row) => {
            }}
            columns={usersColumns}
            useGetData={useAdminUsers}
        />
    </>
}