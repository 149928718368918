import { CheckCircleIcon } from "@heroicons/react/24/outline";

export default function PaymentApproved() {
    return (
        <section className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:px-8">
            <div className="absolute inset-0 -z-10" />
            <div className="mx-auto max-w-2xl lg:max-w-4xl flex flex-col justify-center items-center">
                <CheckCircleIcon className="w-12 h-12 text-green-400" />
                <figure className="mt-4 flex flex-col items-center justify-center">
                    <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9 ">
                        <p>
                            Pagamento aprovado com sucesso, em instantes você receberá um e-mail com os detalhes de acesso a plataforma.
                        </p>
                    </blockquote>
                    <button onClick={() => window.open("https://maisumnabolsa.com.br")} className="cursor-pointer bg-indigo-700 px-3 py-1 rounded-md text-slate-100 w-48 mt-4">Voltar para o site</button>
                    <div className="mt-10 flex items-center justify-center space-x-3 text-base flex-col gap-2">
                        <div className="text-gray-600">Precisa de ajuda? Entre em contato conosco:</div>
                        <div onClick={() => window.open("https://wa.me/5511993581886?text=Ol%C3%A1,%20acabei%20de%20realizar%20o%20pagamento%20e%20preciso%20de%20ajuda.", "_blank")} className="flex flex-row gap-2 items-center cursor-pointer">
                            <img src="/images/whatsapp.png" alt="whatsapp" className="w-8 h-8" />
                        </div>
                    </div>
                </figure>
            </div>
        </section>
    )
}

