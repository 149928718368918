import { useQuery } from "react-query";
import { getAISummary, getLatestAISummary } from "../services/AIService";
import { QueryKey } from "./state";

export const useAISummary = () => {
    return useQuery([QueryKey.aiSummary], async () => {
        const { data } = await getAISummary();
        return data;
    });
}

export const useLatestAISummary = () => {
    return useQuery([QueryKey.latestSummary], async () => {
        const { data } = await getLatestAISummary();
        return data;
    });
}