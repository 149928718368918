/* eslint-disable jsx-a11y/no-redundant-roles */
import { useNavigate } from 'react-router-dom';
import { useCourseModules } from '../hooks/useCourseModules';
import { CourseModule } from '../models/types';
import { getNextClass } from '../services/CourseModuleService';
import CourseProgress from './CourseProgress';

type CourseModuleDetailProps = {
    courseId: string;
}

export default function CourseModulesDetail({ courseId }: CourseModuleDetailProps) {
    const { data: modules } = useCourseModules({ course: courseId });
    const navigate = useNavigate();
    const handleNavigate = async (courseModule: CourseModule) => {
        const { data } = await getNextClass(courseModule.id);
        if (data.class_id) {
            navigate(`/curso/${courseModule.course}/modulo/${courseModule.id}/aula/${data.class_id}`)
        }
    }
    return (
        <div className="flex flex-grow flex-col bg-white border border-gray-300 rounded-lg">
            {modules?.results?.map((courseModule, idx) => {
                return (<div key={idx} onClick={() => handleNavigate(courseModule)} className={`flex flex-grow justify-between flex-col md:flex-row hover:bg-gray-100 hover:cursor-pointer px-3 py-3 ${(idx + 1 === modules?.results?.length) ? "" : "border-b border-gray-300"} truncate`}>
                    <div className="flex flex-grow flex-col truncate gap-1">
                        <h3 className="text-sm font-semibold truncate">{idx + 1}. {courseModule.title}</h3>
                        <h4 className="text-xs font-light truncate text-gray-500">{courseModule.description}</h4>
                    </div>
                    <div className="flex flex-grow flex-col min-w-32 max-w-full md:max-w-32 items-end justify-end md:justify-start gap-1">
                        {courseModule.progress.is_completed ? <span className="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                            Finalizado
                        </span> :
                            <CourseProgress progress={courseModule.progress} />
                        }
                        <h4 className="text-xs font-light">Aulas: {courseModule.progress.completed}/{courseModule.progress.total}</h4>
                    </div>
                </div>)
            })}

        </div>
        // <ul
        //     role="list"
        //     className="divide-y w-full flex-grow divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
        // >
        //     {modules?.results?.map((courseModule) => (
        //         <li key={courseModule.id} onClick={() => handleNavigate(courseModule)} className="flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 hover:cursor-pointer">
        //             <div className="flex min-w-0 gap-x-4">
        //                 <div className="min-w-0 flex-auto">
        //                     <div className="text-sm font-semibold leading-6 text-gray-900">
        //                         <span className="absolute inset-x-0 -top-px bottom-0" />
        //                         {courseModule.title}
        //                     </div>
        //                     <div className="mt-1 flex text-xs leading-5 text-gray-500">
        //                         <div className="relative truncate hover:underline">
        //                             {courseModule.description}
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className="flex shrink-0 items-center gap-x-4">
        //                 <div className="hidden sm:flex sm:flex-col sm:items-end">
        //                     {courseModule.progress.is_completed ? <span className="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
        //                         Finalizado
        //                     </span> :
        //                         <CourseProgress progress={courseModule.progress} />
        //                     }
        //                     <p className="mt-1 text-xs leading-5 text-gray-500">
        //                         Aulas: {courseModule.progress.completed}/{courseModule.progress.total}
        //                     </p>
        //                 </div>
        //                 <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
        //             </div>
        //         </li>
        //     ))}
        // </ul>
    )
}
