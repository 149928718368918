type VideoProps = {
    videoId: string;
    title: string;
    onEnd: () => void;
}

export default function Video({ videoId, title, onEnd }: VideoProps) {
    window.addEventListener("message", (event) => {
        const { data } = event
        if (data.message === 'panda_ended') {
            onEnd();
        }
    }, false);
    return (
        <div className="flex flex-grow">
            <iframe id="panda-XXXX" src={`https://player-vz-377acd8c-682.tv.pandavideo.com.br/embed/?v=${videoId}`}
                title={title}
                allowFullScreen
                className="mt-6 w-[100%] min-h-[210px] sm:min-h-[360px] md:min-h-[572px] lg:min-h-[532px]"></iframe>
        </div>
    )
}