import { useState } from "react";
import BaseCard from "../components/BaseCard";
import { Button } from "../components/Button";
import Card from "../components/Card";
import GoalCard from "../components/GoalCard";
import GoalCreationModal from "../components/modals/GoalCreationModal";
import { GoalCardListSkeletonLoader } from "../components/skeleton/GoalCardSkeleton";
import { useGoals } from "../hooks/useGoals";


export default function GoalsPage() {
    const { data: goals, isLoading, isFetching } = useGoals({});
    const [openCreateGoal, setOpenCreateGoal] = useState(false);
    const [selectedGoal, setSelectedGoal] = useState<string | null>(null);

    return <div className="flex flex-col gap-4">
        <Card>
            <GoalCreationModal
                open={openCreateGoal}
                onClose={() => {
                    setOpenCreateGoal(false)
                    setSelectedGoal(null);
                }}
                goalId={selectedGoal}
            />
            <div className="flex gap-2 items-center w-full justify-between">
                <h3 className="text-md font-semibold text-gray-900">Metas</h3>
            </div>
            {!isLoading && !isFetching && <div className="flex flex-row flex-wrap w-full gap-6">
                {goals?.results?.map((goal) =>
                    <GoalCard goal={goal} onEdit={(goalId: string) => {
                        setSelectedGoal(goalId);
                        setOpenCreateGoal(true);
                    }} />
                )}
                <BaseCard
                    className="max-w-80 min-w-72 border-dashed border-gray-400 py-4"
                    content={<div className="flex px-4 flex-col items-center justify-center gap-1 rounded-t-lg">
                        <h3 className="font-semibold text-sm">Nova meta</h3>
                        <h3 className="text-sm text-center text-gray-600 mb-3">Adicione uma nova meta para acompanhamento</h3>
                        <Button onClick={() => {
                            setSelectedGoal(null);
                            setOpenCreateGoal(true);
                        }} title="Criar Meta" />
                    </div>}
                    icon={<></>}
                    title="Nova Meta"
                    hideTitle={true}
                />
            </div>}
            {(isLoading || isFetching) && <GoalCardListSkeletonLoader />}
        </Card>
    </div>
}