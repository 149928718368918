import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react"
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline"

export function LandingPageFAQ() {

    const faqs = [
        {
            question: "Como funciona a plataforma?",
            answer:
                "Construímos uma plataforma de educação financeira com aulas práticas e ferramentas para auxiliar você a organizar suas finanças de uma vez por todas.",
        },
        {
            question: "Quanto tempo terei acesso?",
            answer:
                "Após o pagamento você terá acesso por 1 ano a plataforma, nesse acesso está incluso todas as aulas e ferramentas já disponibilizadas bem como as que forem lançadas no período de sua assinatura.",
        },
        {
            question: "Posso cancelar a qualquer momento?",
            answer:
                "Você tem até 7 dias após o pagamento para solicitar um estorno através da Kiwify.",
        },
        {
            question: "Meus dados financeiros estão seguros?",
            answer:
                "Sim, todos os seus dados estão protegidos e não solicitaremos senha de nenhum outro aplicativo ou banco.",
        },
        {
            question: "Preciso de conhecimento prévio para começar?",
            answer:
                "Não é necessário nenhum conhecimento prévio, a plataforma surgiu com o intuito de democratizar o acesso a educação financeira, portanto as aulas começam no nível mais básico.",
        },
        {
            question: "Realizei o pagamento, e agora?",
            answer: "Assim que o pagamento for confirmado, você receberá por e-mail um link com sua senha de acesso a plataforma."
        },
        {
            question: "Existe algum acompanhamento individual?",
            answer: "Sim, você receberá um acompanhamento contínuo e personalizado durante o período de sua assinatura para tirar dúvidas e garantir o seu progresso através do WhatsApp."
        },

    ]

    return <div className="mx-auto mt-16 max-w-7xl px-6 pb-16 lg:px-8">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Perguntas frequentes</h2>
            <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                {faqs.map((faq) => (
                    <Disclosure as="div" key={faq.question} className="pt-6">
                        {({ open }) => (
                            <>
                                <dt>
                                    <DisclosureButton className="flex w-full items-start justify-between text-left text-gray-900">
                                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                                        <span className="ml-6 flex h-7 items-center">
                                            {open ? (
                                                <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                            )}
                                        </span>
                                    </DisclosureButton>
                                </dt>
                                <DisclosurePanel as="dd" className="mt-2 pr-12">
                                    <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                </DisclosurePanel>
                            </>
                        )}
                    </Disclosure>
                ))}
            </dl>
        </div>
    </div>
}