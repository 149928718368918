import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import { format, parseISO } from "date-fns";
import { Lead, TableColumn } from "../../models/types";

const openWhatsApp = (phone: string) => {
    const cleanedPhone = phone.replace(/\D/g, '');
    window.open(`https://wa.me/55${cleanedPhone}`, "_blank");
}

export const leadsColumns: TableColumn<Lead>[] = [
    {
        field: "name",
        header: "Name",
        render: (t) => <h4>{t.name}</h4>,
    },
    {
        field: "email",
        header: "E-mail",
        render: (t) => <h4>{t.email}</h4>,
    },
    {
        field: "email",
        header: "Telefone",
        render: (t) => <h4>{t.phone}</h4>,
    },
    {
        field: "created_at",
        header: "Data de cadastro",
        render: (t) => <h4>{format(parseISO(t.created_at), "dd/MM/yyyy HH:mm")}</h4>,
    },
    {
        field: "id",
        header: "Ações",
        render: (t) => <ChatBubbleOvalLeftEllipsisIcon onClick={() => openWhatsApp(t.phone)} className="w-6 h-6 cursor-pointer" />,
    }
]