import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCourseModuleClasses } from "../hooks/useCourseModulesClasses";
import { moduleClassColumns } from "../pages/shared/CoursesTable";
import CourseModuleClassCreationModal from "./modals/CourseModuleClassCreationModal";
import BaseTable from "./monthly-budget/Table";

type CourseModulesTableProps = {
    moduleId: string;
}

export default function CourseModulesClassesTable({ moduleId }: CourseModulesTableProps) {
    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const [selectedClass, setSelectedClass] = useState<string | null>(null);
    const navigate = useNavigate();
    return <div className="mt-5">
        <CourseModuleClassCreationModal moduleId={moduleId} open={openCreate} onClose={() => {
            setOpenCreate(false);
            setSelectedClass(null);
        }} classId={selectedClass} />
        <BaseTable
            title={<h3 className="text-base font-semibold leading-6 text-gray-900">Aulas</h3>}
            onRowClick={(row) => {
                setSelectedClass(row.id);
                setOpenCreate(true);
            }}
            columns={moduleClassColumns}
            params={{ module: moduleId }}
            useGetData={useCourseModuleClasses}
            onAdd={() => {
                setOpenCreate(true)
            }}
            onAddTitle="Aula"
        />
    </div>
}