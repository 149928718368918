import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { generateAISummary } from "../../services/AIService";
import { Button } from "../Button";

export default function AIReportAvailable() {
    const navigate = useNavigate();
    const { setNotification } = useAppContext();
    const [loading, setLoading] = useState<boolean>(false);
    const generateSummary = async () => {
        try {
            setLoading(true);
            await generateAISummary();
        } catch (e) {
            setNotification({
                message: "Erro ao gerar relatório",
                title: "Erro ao gerar relatório",
                type: "error"
            })
        } finally {
            setLoading(false);
        }
    }
    return <div className="bg-white w-full flex-col px-4 py-1 gap-3 pb-4 rounded-b-xl flex justify-center flex-grow items-center">
        <div className="flex flex-row items-start w-full gap-4 pt-3 pb-0">
            <img
                alt=""
                src="/images/ai.png"
                className="h-8 w-8 flex-none rounded-full bg-gray-50"
            />
            <div className="border flex flex-col border-gray-300 rounded-md h-full py-2 px-2 gap-1">
                <h4 className="text-xs text-gray-600 font-bold">Alberto <span className="font-light text-gray-600">enviou:</span></h4>
                <h4 className="text-xs text-gray-900 font-light">
                    <div className="flex flex-col gap-1 py-2">
                        <p className="text-xs text-gray-900 font-semibold">Você já pode gerar seu relatório!</p>
                        <p className="text-xs text-gray-900 font-light">Olá! Sou eu Alberto novamente. Vim avisar que você já pode gerar um relatório com insights e dicas sobre seu orçamento atual, clique no botão abaixo para começar.</p>
                    </div>
                </h4>
            </div>
        </div>
        <Button onClick={() => generateSummary()} disabled={loading} loading={loading} title="Gerar Relatório" />
    </div>
}