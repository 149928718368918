import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../hooks/useUsers";
import { useAuthenticationContext } from "../context/AuthenticationContext";
import LoadingSpinner from "./Loading";

type Props = { children: any };

const AuthProtect = (props: Props) => {
    const { children } = props;
    const { user, setUser } = useAuthenticationContext();
    const { data: authUser, isLoading } = useUser();

    useEffect(() => {
        if (authUser) {
            setUser(authUser)
        }
    }, [authUser]);

    if (isLoading) {
        return <div className="h-screen bg-white align-middle justify-center">
            <LoadingSpinner />
        </div>
    }

    if (!user && !isLoading && !authUser) {
        return <Navigate to="/home" />;
    }

    return children;
};

export default AuthProtect;
