import { useEffect, useState } from "react";
import AdminTabs from "../components/AdminTabs";
import CoursesAdmin from "../components/CoursesAdmin";
import UsersAdmin from "../components/CourseUsers";
import Leads from "../components/Leads";
import { useUser } from "../hooks/useUsers";

export default function AdminPage() {
    const { data: user } = useUser();
    const tabs = [
        { name: "Cursos" },
        { name: "Usuários" },
        { name: "Leads" }
    ]
    const [currentTab, setCurrentTab] = useState<string>("Cursos");
    useEffect(() => {
        if (!user?.is_admin) {
            window.location.href = '/inicio';
        }
    }, [user])
    return <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
            <AdminTabs tabs={tabs} setCurrent={setCurrentTab} current={currentTab} />
            {currentTab === "Cursos" && <CoursesAdmin />}
            {currentTab === "Usuários" && <UsersAdmin />}
            {currentTab === "Leads" && <Leads />}
        </div>
    </div>
}