import { BanknotesIcon, CalendarDaysIcon, ChartBarSquareIcon, PlayIcon, SparklesIcon, UserCircleIcon } from "@heroicons/react/24/outline";

export default function OurProcess() {
    return <div className="flex pt-8 w-full flex-col max-w-2xl">
        <div className="flex flex-row items-center gap-1 flex-grow">
            <PlayIcon className="h-6 w-6 text-indigo-700" />
            <h1 className="text-2xl font-semibold text-indigo-700">Aulas</h1>
        </div>
        <div className="mt-3 text-sm text-gray-600 pl-4 border-l border-b border-dashed py-5 border-gray-300">
            <div className="flex flex-row items-center gap-6">
                <p><span className="font-semibold">Educação financeira, investimentos, cartões de crédito e muito mais!</span><br /><br />Desenvolvemos uma plataforma própria e exclusiva para você assistir nossas aulas no mesmo ambiente onde utilizará nossas ferramentas.<br /><br />
                    Você terá acesso as nossas trilhas já disponibilizadas e todas que forem lançadas durante o período da sua assinatura.<br /><br />
                    Todo conteúdo pode ser acessado pelo seu celular, tablet ou computador.
                </p>
                <img src="/images/app/aulas.png" alt="Aulas" className="w-1/4 md:w-1/6 rounded-lg" />
            </div>
        </div>
        <div className="border-r border-dashed borded-gray-300 h-6"></div>
        <div className="flex flex-row items-center gap-1 flex-grow justify-end pt-2">
            <CalendarDaysIcon className="h-6 w-6 text-indigo-700" />
            <h1 className="text-2xl font-semibold text-indigo-700">Orçamento</h1>
        </div>
        <div className="mt-3 text-sm text-gray-600 pr-4 border-r border-b border-dashed py-5 border-gray-300">
            <div className="flex flex-row items-center gap-6">
                <img src="/images/app/orcamento.png" alt="Aulas" className="w-1/4 md:w-1/6 rounded-lg" />
                <p><span className="font-semibold">Defina um orçamento para o seu dinheiro</span><br /><br />
                    Oferecemos ferramentas para auxiliar você a criar um orçamento personalizado, com base nas suas metas e objetivos pessoais.
                </p>
            </div>
        </div>
        <div className="border-l border-dashed borded-gray-300 h-6"></div>
        <div className="flex flex-row items-center gap-1 flex-grow flex-start pt-2">
            <ChartBarSquareIcon className="h-6 w-6 text-indigo-700" />
            <h1 className="text-2xl text-left font-semibold text-indigo-700">Metas</h1>
        </div>
        <div className="mt-3 text-sm text-gray-600 pl-4 border-l border-b border-dashed py-5 border-gray-300">
            <div className="flex flex-row items-center flex-grow justify-between gap-6">
                <p><span className="font-semibold">Acompanhe a realização dos seus sonhos</span><br /><br />
                    Defina metas financeiras e acompanhe o progresso delas.<br />
                    Receba notificações e dicas para te ajudar a alcançar seus objetivos.
                </p>
                <img src="/images/app/metas.png" alt="Aulas" className="w-1/4 md:w-1/6 rounded-lg" />
            </div>
        </div>
        <div className="border-r border-dashed borded-gray-300 h-6"></div>
        <div className="flex flex-row items-center gap-1 flex-grow justify-end pt-2">
            <BanknotesIcon className="h-6 w-6 text-indigo-700" />
            <h1 className="text-2xl text-right font-semibold text-indigo-700">Transações</h1>
        </div>
        <div className="mt-3 text-sm text-gray-600 pr-4 border-r border-b border-dashed py-5 border-gray-300">
            <div className="flex flex-row items-center gap-6">
                <img src="/images/app/transacoes.png" alt="Aulas" className="w-1/4 md:w-1/6 rounded-lg" />
                <p><span className="font-semibold">Tudo na palma das suas mãos</span><br /><br />
                    Crescer seu patrimônio não se trata somente de economizar, mas também aprender a direcionar seu dinheiro para o local certo e ter controle de cada centavo.<br /><br />
                    Com nossa plataforma você pode anotar todos os seus gastos, controlar suas despesas mensais, filtrar por despesas pendente de pagamento e categoria alem de ter uma visão clara de onde seu dinheiro está sendo gasto.
                </p>
            </div>
        </div>
        <div className="border-l border-dashed borded-gray-300 h-6"></div>
        <div className="flex flex-row items-center gap-1 flex-grow pt-2">
            <SparklesIcon className="h-6 w-6 text-indigo-700" />
            <h1 className="text-2xl text-left font-semibold text-indigo-700">Inteligência Artificial</h1>
        </div>
        <div className="mt-3 text-sm text-gray-600 pl-4 border-l border-b border-dashed py-5 border-gray-300">
            <div className="flex flex-row items-center gap-6">
                <p><span className="font-semibold">Use o melhor da tecnologia</span><br /><br />
                    Após passar pelas etapas anteriores, nossa inteligência artificial consegue elaborar relatórios sobre cada uma de suas despesas para sugerir ideias e identificar gastos que podem ser ajustados.
                </p>
                <img src="/images/app/alberto.png" alt="Aulas" className="w-1/4 md:w-1/6 rounded-lg" />
            </div>
        </div>
        <div className="border-r border-dashed borded-gray-300 h-6"></div>
        <div className="flex flex-row items-center gap-1 flex-grow justify-end pt-2">
            <UserCircleIcon className="h-6 w-6 text-indigo-700" />
            <h1 className="text-2xl text-right font-semibold text-indigo-700">Atendimento Exclusivo</h1>
        </div>
        <div className="mt-3 text-sm text-gray-600 pr-4 border-r border-b border-dashed py-5 border-gray-300">
            <div className="flex flex-row items-center gap-6">
                <img src="/images/logo.png" alt="Aulas" className="w-1/4 md:w-1/6 rounded-lg" />
                <p><span className="font-semibold">Você é nossa prioridade</span><br /><br />
                    Você contará com um atendimento personalizado via WhatsApp com nossos especialistas para ajudar em quaisquer dúvidas ou ajuda necessária para utilizar a plataforma.<br /><br />
                    Nosso maior objetivo é transformar sua vida financeira e estaremos aqui para te ajudar em cada etapa.
                </p>
            </div>
        </div>
    </div>
}