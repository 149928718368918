import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { useCourseModuleClass } from "../../hooks/useCourseModulesClasses";
import { createCourseModuleClass, deleteCourseModuleClass, updateCourseModuleClass } from "../../services/CourseModuleClassService";
import { CustomInput } from "../CustomInput";
import BaseModal from "./BaseModal";
import ConfirmationModal from "./ConfirmationModal";

type CourseModuleClassCreationModalProps = {
    open: boolean;
    onClose: () => void;
    moduleId: string;
    classId: string | null;
}

export default function CourseModuleClassCreationModal({ open, onClose, moduleId, classId }: CourseModuleClassCreationModalProps) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [classNumber, setClassNumber] = useState<string>("1");
    const [externalId, setExternalId] = useState<string>("");
    const navigate = useNavigate();

    const { data: moduleClass, isLoading } = useCourseModuleClass(classId);

    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const { setNotification } = useAppContext();

    useEffect(() => {
        if (moduleClass) {
            setName(moduleClass.title);
            setDescription(moduleClass.description)
            setClassNumber(`${moduleClass.class_number}`)
            setExternalId(moduleClass.external_id)
        } else {
            setName("");
            setDescription("");
            setClassNumber("1");
            setExternalId("");
        }
    }, [moduleClass, open])

    const handleDeleteCourse = async () => {
        try {
            if (classId) {
                await deleteCourseModuleClass(classId);
                setNotification({
                    type: 'success',
                    message: 'aula excluida com sucesso',
                    title: 'aula excluida'
                })
                navigate("/admin")
                onClose();
                setOpenConfirmation(false);
            } else {
                setNotification({
                    type: 'error',
                    message: 'Nenhum aula selecionada',
                    title: 'Erro ao excluir aula'
                })
            }
        } catch (error) {
            setNotification({
                type: 'error',
                message: 'Tente novamente',
                title: 'Erro ao excluir aula'
            })
        }
    }

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (name === "") {
                return;
            }
            if (description === "") {
                return;
            }
            if (classId) {
                await updateCourseModuleClass(classId, {
                    title: name,
                    description,
                    class_number: Number(classNumber),
                    external_id: externalId,
                })
            } else {
                await createCourseModuleClass({
                    title: name,
                    description,
                    class_number: Number(classNumber),
                    module: moduleId,
                    external_id: externalId,
                })
            }
            onClose();
            setNotification({
                type: 'success',
                message: `Aula ${classId ? "editado" : "adicionado"} com sucesso`,
                title: `Aula`
            })
        } catch (error) {
            setNotification({
                type: 'error',
                message: `Erro ao ${classId ? "editar" : "adicionar"} o Aula, tente novamente`,
                title: `Aula`
            })
        } finally {
            setLoading(false);
        }
    }

    return (
        <BaseModal open={open} onClose={onClose}>
            <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold">{classId ? "Editar Aula" : "Criar Aula"}</h2>
                    <div className="flex items-center gap-2">
                        {classId && <span onClick={() => setOpenConfirmation(true)} className={`cursor-pointer inline-flex items-center bg-red-300 gap-x-1.5 rounded-md px-2 py-1 text-xs font-light text-red-900 ring-1 ring-inset ring-gray-200 hover:ring-gray-300 hover:bg-red-400 hover:text-white`}>
                            Excluir
                        </span>}
                        <XMarkIcon onClick={() => onClose()} className="h-5 w-5 cursor-pointer" />
                    </div>
                </div>
                <CustomInput title="Titulo"
                    description="Informe um titulo para o aula"
                    error={""}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    value={name}
                    placeholder="Titulo do aula"
                    name="course-name"
                    id="course-name"
                    type="text"
                />
                <CustomInput title="Descrição"
                    description="Informe uma descrição para o aula"
                    error={""}
                    onChange={(e) => {
                        setDescription(e.target.value)
                    }}
                    value={description}
                    placeholder="Descrição do aula"
                    name="course-description"
                    id="description-name"
                    type="text"
                />
                <CustomInput title="ID Externo"
                    description="Informe o ID externo do video"
                    error={""}
                    onChange={(e) => {
                        setExternalId(e.target.value)
                    }}
                    value={externalId}
                    placeholder="ID Externo"
                    name="name-external-id"
                    id="n-external-id"
                    type="text"
                />
                <CustomInput title="Número do Módulo"
                    description="O número do módulo serve para ordenação em que ele aparecerá para o usuário"
                    error={""}
                    onChange={(e) => {
                        setClassNumber(e.target.value)
                    }}
                    value={classNumber}
                    placeholder="Número do Módulo"
                    name="module-description"
                    id="module-description-name"
                    type="text"
                />

                <button disabled={loading} onClick={() => {
                    handleSubmit();
                }} className="bg-indigo-600 hover:bg-indigo-700 rounded-md text-white text-sm font-medium py-1">{classId ? "Salvar Alterações" : "Criar Aula"}</button>
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    onConfirm={() => handleDeleteCourse()}
                    onCancel={() => setOpenConfirmation(false)}
                    title="Excluir Meta"
                    message={`Deseja realmente excluir a aula ${moduleClass?.title}`} />
            </div>
        </BaseModal>
    )
}
