import Chart from 'react-apexcharts';

type DividendChartProps = {
    labels: string[];
    dividends: number[];
}

export const DividendsChart = ({ labels, dividends }: DividendChartProps) => {
    return <Chart options={{
        chart: {
            id: 'apexchart-example',
            toolbar: {
                show: false
            },
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: labels,
            labels: {
                style: {
                    fontSize: '12px',
                }
            }
        }
    }} series={[{
        name: 'Dividendos',
        data: dividends,
    }]} type="bar" width={500} />
}