export default function PrivacyPolicy() {
    return <div className="flex flex-col gap-2 p-1">
        <h4 className="text-md">
            A proteção da sua privacidade é muito importante para nós. <br />
            Esta Política de Privacidade descreve como coletamos, utilizamos, armazenamos e protegemos suas informações pessoais quando você preenche nosso formulário de lead.
        </h4>
        <h4 className=" text-md">
            <b>1.</b> A coleta de informações ocorre ao preencher nosso formulário de lead, onde solicitamos informações como seu nome, e-mail, telefone e outros dados relevantes para atender sua solicitação. Essas informações são coletadas com o objetivo de fornecer uma melhor experiência e facilitar a comunicação com você.
        </h4>
        <h4 className=" text-md">
            <b>2.</b> As informações coletadas serão usadas para:
        </h4>
        <h4 className="  text-md">
            Entrar em contato com você a respeito de produtos, serviços ou conteúdos que possam ser do seu interesse;<br />
            Melhorar nosso atendimento e personalizar nossa comunicação com base em suas preferências.<br />
        </h4>
        <h4 className=" text-md">
            <b>3.</b> Armazenamento e Segurança das Informações:
        </h4>
        <h4 className="  text-md">
            Nós adotamos medidas de segurança adequadas para proteger suas informações pessoais contra acessos não autorizados, perda, divulgação ou alteração indevida. As informações são armazenadas em servidores seguros, e apenas pessoas autorizadas têm acesso a elas.
        </h4>
        <h4 className=" text-md">
            <b>4.</b> Compartilhamento de Informações: Nós não venderemos, alugaremos ou compartilharemos suas informações pessoais com terceiros sem o seu consentimento, exceto quando necessário para:
        </h4>
        <h4 className="  text-md">
            Cumprir com exigências legais;<br />
            Proteger os direitos e a segurança de nossa empresa, de nossos clientes ou do público;<br />
            Fornecer serviços através de parceiros cuidadosamente selecionados, que também seguem nossas políticas de privacidade.<br />

        </h4>
        <h4 className=" text-md">
            <b>5.</b> Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento. Para fazer isso, entre em contato conosco através do e-mail <span className="text-blue-800">contato@maisumnabolsa.com.br</span>. Você também pode optar por não receber mais nossas comunicações solicitando através do mesmo e-mail.
        </h4>
        <h4 className=" text-md">
            <b>6.</b> Podemos utilizar cookies para melhorar a navegação no nosso site e personalizar sua experiência. Você pode desativar os cookies a qualquer momento através das configurações do seu navegador.

        </h4>
        <h4 className=" text-md">
            <b>7.</b> Reservamo-nos o direito de modificar esta Política de Privacidade a qualquer momento. Todas as alterações serão publicadas nesta página e, quando apropriado, notificaremos você por e-mail ou por meio de um aviso em nosso site.
        </h4>
        <h4 className=" text-md">
            <b>8.</b>Se você tiver qualquer dúvida sobre esta Política de Privacidade ou sobre o tratamento de seus dados, entre em contato conosco pelo e-mail <span className="text-blue-800">contato@maisumnabolsa.com.br</span>.
        </h4>
    </div>
}