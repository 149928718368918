/* eslint-disable jsx-a11y/no-redundant-roles */
import { CheckIcon } from "@heroicons/react/20/solid"
import { config } from "../../../config/env"
import { Button } from "../../Button"

export function LandingPagePriceSection() {

    const tiers = [
        {
            name: 'Plano Básico',
            id: 'tier-basic',
            href: config.payment.kiwify_checkout_url,
            priceMonthly: 'R$24,80',
            fullPrice: 'R$247,00',
            description: 'Nossa assinatura oferece tudo o que você precisa saber para assumir o controle do seu futuro financeiro',
            features: [
                'Relatórios da inteligência artificial',
                'Todas Ferramentas',
                // 'Definição de metas',
                // 'Calculadoras personalizadas',
                'Curso de organização financeira',
                '1 ano de acesso',
                // 'Suporte personalizado',
            ],
            featured: true,
            recommended: false,
            paymentUrl: config.payment.kiwify_lower_price_checkout_url,
        },
        {
            name: 'Plano Investidor',
            id: 'tier-enterprise',
            href: config.payment.kiwify_checkout_url,
            priceMonthly: 'R$49,90',
            fullPrice: 'R$497,00',
            description: 'Nossa assinatura oferece tudo o que você precisa saber para assumir o controle do seu futuro financeiro',
            features: [
                'Relatórios da inteligência artificial',
                'Todas Ferramentas',
                // 'Definição de metas',
                // 'Calculadoras personalizadas',
                'Todos cursos',
                'Suporte personalizado',
                '1 ano de acesso',
            ],
            featured: true,
            recommended: true,
            paymentUrl: config.payment.kiwify_checkout_url,
        },
    ]

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    return <div id="offer" className="relative isolate bg-gray-900 px-6 py-10 lg:px-8">
        <div
            className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
            aria-hidden="true"
        >
            <div
                className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                style={{
                    clipPath:
                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
            />
        </div>
        <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
            <span className="mt-6 inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                O melhor custo-benefício
            </span>
            <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                Menos de R$0,83/dia
            </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-white">
            Nascemos com o propósito de democratizar o acesso à educação financeira de qualidade. Por isso, oferecemos um plano acessível para todos os bolsos.
        </p>
        <div className="mt-16 flex justify-center gap-8 flex-col md:flex-row items-center md:items-start">
            {tiers.map((tier, tierIdx) => (
                <div
                    key={tier.id}
                    className={classNames(
                        tier.featured ? 'relative bg-white shadow-2xl' : 'bg-white/60 sm:mx-8 lg:mx-0',
                        tier.featured
                            ? ''
                            : tierIdx === 0
                                ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none'
                                : 'sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl',
                        'rounded-3xl p-8 ring-2 ring-gray-900/10 sm:p-10 max-w-96'
                    )}
                >
                    <div className="flex flex-row items-center justify-between">
                        <h3
                            id={tier.id}
                            className={classNames(
                                tier.featured ? 'text-indigo-600' : 'text-indigo-600',
                                'text-base font-semibold leading-7'
                            )}
                        >
                            {tier.name}
                        </h3>
                        {tier.recommended && <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-indigo-700/10">
                            Recomendado
                        </span>}
                    </div>
                    <p className="mt-4 flex flex-col items-baseline gap-x-2 gap-y-2">
                        <div className="flex flex-col">
                            <span className="text-indigo-600">
                                12x <span className="text-gray-900">de</span>
                            </span>
                            <span
                                className={classNames(
                                    tier.featured ? 'text-gray-900' : 'text-gray-900',
                                    'text-5xl font-bold tracking-tight'
                                )}
                            >
                                {tier.priceMonthly}
                            </span>
                        </div>
                        {/* <span className={classNames(tier.featured ? 'text-gray-400' : 'text-gray-500', 'text-base')}>
                            ou <span className="text-indigo-600">{tier.fullPrice}</span> à vista
                        </span> */}
                    </p>
                    {/* <p
                        className={classNames(tier.featured ? 'text-gray-500' : 'text-gray-500', 'mt-6 text-base leading-7')}
                    >
                        {tier.description}
                    </p> */}
                    <ul
                        role="list"
                        className={classNames(
                            tier.featured ? 'text-gray-500' : 'text-gray-500',
                            'space-y-3 text-sm leading-6 mt-6 sm:mt-6'
                        )}
                    >
                        {tier.features.map((feature) => (
                            <li key={feature} className="flex gap-x-3">
                                <CheckIcon
                                    className={classNames(
                                        tier.featured ? 'text-indigo-600' : 'text-indigo-600',
                                        'h-6 w-5 flex-none'
                                    )}
                                    aria-hidden="true"
                                />
                                {feature}
                            </li>
                        ))}
                    </ul>
                    <Button title="Assine agora mesmo"
                        className="mt-4 text-center py-3 font-bold"
                        onClick={() => window.open(tier.paymentUrl)} />
                </div>
            ))}
        </div>
    </div>
}