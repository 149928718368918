import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { EvaluateStockBody } from "../../models/types";
import { handleMoneyChange } from "../../utils/handleMoneyChange";
import { CustomInput } from "../CustomInput";
import BaseModal from "./BaseModal";

type StockEvaluationModalProps = {
    open: boolean;
    onClose: () => void;
    onChange: (body: EvaluateStockBody) => void;
}

export default function StockEvaluationModal({ open, onClose, onChange }: StockEvaluationModalProps) {
    const tickers = [
        { id: 1, name: 'MXRF11' },
        { id: 2, name: 'HGLG11' },
        { id: 3, name: 'BBAS3' }
    ]
    const [ticker, setTicker] = useState(tickers[0]);
    const [amount, setAmount] = useState("");
    const [firstTransaction, setFirstTransaction] = useState("");


    return (
        <BaseModal open={open} onClose={onClose}>
            <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold">{"Simular histórico"}</h2>
                </div>
                <Listbox value={ticker} onChange={setTicker}>
                    <h4 className="block text-sm font-medium text-gray-900">Ativo</h4>
                    <h5 className="text-sm text-gray-500">Selecione um ativo para simulação.</h5>
                    <div className="relative">
                        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            <span className="block truncate">{ticker.name}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                            </span>
                        </ListboxButton>

                        <ListboxOptions

                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                        >
                            {tickers.map((ticker) => (
                                <ListboxOption
                                    key={ticker.id}
                                    value={ticker}
                                    className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                >
                                    <span className="block truncate font-normal group-data-[selected]:font-semibold">{ticker.name}</span>

                                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                        <CheckIcon aria-hidden="true" className="h-5 w-5" />
                                    </span>
                                </ListboxOption>
                            ))}
                        </ListboxOptions>
                    </div>
                </Listbox>
                <CustomInput title="Aporte Mensal"
                    description="Insira um valor de aporte mensal, a simulação considerará a compra de novos ativos com esse valor no primeiro dia útil do mês"
                    error=""
                    onChange={(e) => {
                        handleMoneyChange(e, setAmount)
                    }}
                    value={amount}
                    placeholder="0.00"
                    fixedPlaceholder="R$"
                    name="amount"
                    id="amount"
                    type="text"
                />
                <CustomInput title="Primeira Transação"
                    description="Insira uma data passada para simular o primeiro dia de compra do ativo"
                    error=""
                    onChange={(e) => {
                        setFirstTransaction(e.target.value)
                    }}
                    value={firstTransaction}
                    placeholder="01/01/2020"
                    name="first-transaction"
                    id="first-transaction"
                    type="date"
                />
                <button onClick={() => {
                    onChange({
                        ticker_name: ticker.name,
                        initial_amount: Number(amount),
                        first_transaction: firstTransaction,
                    })
                    onClose();
                }} className="bg-indigo-600 hover:bg-indigo-700 rounded-md text-white text-sm font-medium py-1">Simular</button>
            </div>
        </BaseModal>
    )
}
