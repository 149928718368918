import AISummaryDetail from "../components/AISummaryDetail";

export default function AISummaryPage() {
    return <div className="bg-white shadow sm:rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:p-6 max-w-full">
            <div className="flex flex-col gap-1">
                <div className="flex flex-row items-center justify-between">
                    <h4 className="text-gray-900 text-md font-semibold">Sugestões do Alberto</h4>
                </div>
                <h4 className="text-gray-600  text-sm font-light">Alberto está aqui para oferecer dicas rápidas e práticas sobre como organizar suas finanças, economizar, e alcançar seus objetivos financeiros. No entanto, é sempre importante lembrar que as sugestões fornecidas são geradas através de nossa inteligência artificial. Avalie cuidadosamente todas as recomendações antes de tomar qualquer ação.</h4>
            </div>
            <AISummaryDetail />
        </div>
    </div>
}