import queryClient, { QueryKey } from "../hooks/state";
import { CreateTransaction, Transaction, TransactionFilterParams, UpdateTransaction } from "../models/types";
import { PaginatedResponse } from './../models/types';
import { httpDeleteAuthenticated, httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./Api";

const endpoints = {
  list: () => "/central/central/transactions",
  retrieve: (id: string) => `/central/central/transactions/${id}`,
};

export const getTransaction = async (objectId: string) => {
  return httpGetAuthenticated<Transaction>(endpoints.retrieve(objectId));
}

export const getTransactions = async (params: TransactionFilterParams) => {
  return httpGetAuthenticated<PaginatedResponse<Transaction>>(endpoints.list(), {
    params,
  });
}

export const createTransaction = async (payload: CreateTransaction) => {
  const response = await httpPostAuthenticated<Transaction>(endpoints.list(), payload);
  queryClient.invalidateQueries(QueryKey.transaction);
  return response.data;
}

export const updateTransaction = async (id: string, payload: UpdateTransaction) => {
  const response = await httpPatchAuthenticated(endpoints.retrieve(id), payload);
  queryClient.invalidateQueries(QueryKey.transaction);
  return response.data;
}

export const deleteTransaction = async (id: string) => {
  const response = await httpDeleteAuthenticated(endpoints.retrieve(id));
  queryClient.invalidateQueries(QueryKey.transaction);
  return response.data;
}