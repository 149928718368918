import { Lead, LeadsFetchParams, PaginatedResponse } from "../models/types";
import { httpGetAuthenticated, httpPostAuthenticated } from "./Api";

const endpoints = {
  list: () => "/central/free_material",
  admin: () => "/admin/leads",
};

export const createLead = async (payload: {
  name: string;
  email: string;
  phone: string;
}) => {
  const response = await httpPostAuthenticated<{ url: string }>(endpoints.list(), payload);
  return response.data;
}

export const getLeads = async (params: LeadsFetchParams) => {
  return httpGetAuthenticated<PaginatedResponse<Lead>>(endpoints.admin(), {
    params,
  });
}