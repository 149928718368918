import queryClient, { QueryKey } from "../hooks/state";
import { File, FileFilterParams, PaginatedResponse } from "../models/types";
import { httpDeleteAuthenticated, httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./Api";

const endpoints = {
  list: () => "/central/central/files",
  retrieve: (id: string) => `/central/central/files/${id}`,
};

export const getFile = async (objectId: string) => {
  return httpGetAuthenticated<File>(endpoints.retrieve(objectId));
}

export const getFiles = async (params: FileFilterParams) => {
  return httpGetAuthenticated<PaginatedResponse<File>>(endpoints.list(), {
    params,
  });
}

export const createFile = async ({ name, description, category, file, free_download }: {
  name: string;
  description: string | null;
  category: string;
  file: any;
  free_download: boolean;
}) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", name);
  if (description) {
    formData.append("description", description);
  }
  formData.append("category", category);
  formData.append("free_download", free_download.toString());
  const response = await httpPostAuthenticated(endpoints.list(), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  queryClient.invalidateQueries(QueryKey.file);
  return response.data;
}

export const updateFile = async (id: string, payload: {
  name: string;
  description: string | null;
  category: string;
  free_download: boolean;
}) => {
  const response = await httpPatchAuthenticated(endpoints.retrieve(id), payload);
  queryClient.invalidateQueries(QueryKey.file);
  return response.data;
}

export const deleteFile = async (id: string) => {
  const response = await httpDeleteAuthenticated(endpoints.retrieve(id));
  queryClient.invalidateQueries(QueryKey.file);
  return response.data;
}