import { Progress } from "../models/types";

export default function CourseProgress({ progress }: { progress: Progress }) {
    const currentProgress = Number((progress.completed * 100) / progress.total).toFixed(2);
    const fixedProgress = Number(currentProgress) > 100 ? 100 : currentProgress;
    return <div aria-hidden="true" className="flex flex-grow w-full flex-row items-center gap-2">
        <div className="flex flex-grow rounded-full bg-gray-200">
            <div style={{ width: `${fixedProgress}%` }} className="h-2 rounded-full bg-green-700" />
        </div>
        <div className="flex min-w-fit text-sm font-medium text-green-700 text-right">{fixedProgress}%</div>
    </div >
}