import queryClient, { QueryKey } from "../hooks/state";
import { CreateGoal, Goal, GoalFilterParams } from "../models/types";
import { PaginatedResponse } from './../models/types';
import { httpDeleteAuthenticated, httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./Api";

const endpoints = {
  list: () => "/central/central/goals",
  retrieve: (id: string) => `/central/central/goals/${id}`,
};

export const getGoal = async (objectId: string) => {
  return httpGetAuthenticated<Goal>(endpoints.retrieve(objectId));
}

export const getGoals = async (params: GoalFilterParams) => {
  return httpGetAuthenticated<PaginatedResponse<Goal>>(endpoints.list(), {
    params,
  });
}

export const createGoal = async (payload: CreateGoal) => {
  const response = await httpPostAuthenticated<Goal>(endpoints.list(), payload);
  queryClient.invalidateQueries(QueryKey.goals);
  return response.data;
}

export const updateGoal = async (id: string, payload: CreateGoal) => {
  const response = await httpPatchAuthenticated(endpoints.retrieve(id), payload);
  queryClient.invalidateQueries(QueryKey.goals);
  return response.data;
}

export const deleteGoal = async (id: string) => {
  const response = await httpDeleteAuthenticated(endpoints.retrieve(id));
  queryClient.invalidateQueries(QueryKey.goals);
  return response.data;
}