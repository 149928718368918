import { useQuery } from "react-query";
import { getAllUsers, getPaymentIntent, getUser } from "../services/UserService";
import { QueryKey } from "./state";

export const useUser = () => {
    return useQuery([QueryKey.users], async () => {
        const { data } = await getUser();
        return data;
    });
}

export const usePaymentIntent = () => {
    return useQuery([QueryKey.paymentIntent], async () => {
        const { data } = await getPaymentIntent();
        return data;
    });
}

export const useAdminUsers = () => {
    return useQuery([QueryKey.users, "Admin"], async () => {
        const { data } = await getAllUsers();
        return data;
    })
}