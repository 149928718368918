import { XMarkIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { useTransactionCategory, useTransactionCategoryTypes } from "../../hooks/useTransactionCategories";
import { TransactionCategoryType } from "../../models/types";
import { createTransactionCategory, deleteTransactionCategory, updateTransactionCategory } from "../../services/TransactionCategoriesService";
import { transactionCategoriesTypes } from "../../utils/helper";
import { CustomInput } from "../CustomInput";
import CustomSelectDropdown from "../monthly-budget/CustomSelectDropdown";
import BaseModal from "./BaseModal";
import ConfirmationModal from "./ConfirmationModal";

type TransactionCategoryModalProps = {
    open: boolean;
    onClose: () => void;
    categoryId: string | null;
}

export default function TransactionCategoryModal({ open, onClose, categoryId }: TransactionCategoryModalProps) {
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [type, setType] = useState<TransactionCategoryType | null>(null);
    const [typeError, setTypeError] = useState("");
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const { data: category, isLoading } = useTransactionCategory(categoryId);
    const [loading, setLoading] = useState(false);
    const { setNotification } = useAppContext();
    useEffect(() => {
        if (category) {
            setName(category.name);
            setType(transactionCategoriesTypes.find((id) => id.id === category.category_type) ?? null);
        } else {
            setName("");
        }
    }, [category, open])

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (!name || (name && name === "")) {
                return setNameError("Informe um nome para categoria");
            } else {
                setNameError("");
            }
            if (!type) {
                return setTypeError("Informe um tipo para categoria");
            } else {
                setTypeError("");
            }
            if (categoryId) {
                await updateTransactionCategory(categoryId, { name, category_type: type.id });
            } else {
                await createTransactionCategory({ name, category_type: type.id });
            }
            onClose();
            setNotification({
                type: 'success',
                message: `Categoria ${categoryId ? "editada" : "adicionada"} com sucesso`,
                title: `Distribuição de Orçamento`
            })
        } catch (error) {
            setNotification({
                type: 'error',
                message: `Erro ao ${categoryId ? "editar" : "adicionar"} a configuração, tente novamente`,
                title: `Distribuição de Orçamento`
            })
        } finally {
            setLoading(false);
        }
    }

    const handleDelete = async () => {
        try {
            setLoading(true);
            if (categoryId) {
                await deleteTransactionCategory(categoryId);
                onClose();
                setNotification({
                    message: "Categoria excluida com sucesso",
                    title: "Sucesso",
                    type: "success"
                })
            }
        } catch (e) {
            setNotification({
                message: "Erro ao excluir categoria",
                title: "Erro",
                type: "error"
            })
        } finally {
            setLoading(false);
        }
    }

    return (
        <BaseModal open={open} onClose={onClose}>
            <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold">{categoryId ? "Editar Categoria" : "Criar Categoria"}</h2>
                    <div className="flex items-center gap-2">
                        {categoryId && <span onClick={() => setOpenConfirmation(true)} className={`cursor-pointer inline-flex items-center bg-red-300 gap-x-1.5 rounded-md px-2 py-1 text-xs font-light text-red-900 ring-1 ring-inset ring-gray-200 hover:ring-gray-300 hover:bg-red-400 hover:text-white`}>
                            Excluir
                        </span>}
                        <XMarkIcon onClick={() => onClose()} className="h-5 w-5 cursor-pointer" />
                    </div>
                </div>
                <CustomInput title="Nome"
                    description="Informe um nome para a categoria de gastos"
                    error={nameError}
                    onChange={(e) => {
                        setName(e.target.value)
                        setNameError("");
                    }}
                    value={name}
                    placeholder="Nome da categoria"
                    name="categoria"
                    id="categoria-name"
                    type="text"
                />
                <CustomSelectDropdown<TransactionCategoryType, any>
                    params={{}}
                    label="Tipo"
                    useGetOptions={useTransactionCategoryTypes}
                    nameKey="name" onChange={setType} selected={type}
                    className="max-w-sm"
                />
                <button disabled={loading} onClick={() => {
                    handleSubmit();
                }} className="bg-indigo-600 hover:bg-indigo-700 rounded-md text-white text-sm font-medium py-1">{categoryId ? "Salvar Alterações" : "Criar Categoria"}</button>
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    onConfirm={() => handleDelete()}
                    onCancel={() => setOpenConfirmation(false)}
                    title="Excluir Meta"
                    message={`Deseja realmente excluir a categoria ${category?.name}`} />
            </div>
        </BaseModal>
    )
}
