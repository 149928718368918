import { useQuery } from "react-query";
import { getTransaction, getTransactions } from "../services/TransactionService";
import { QueryKey } from "./state";
import { TransactionFilterParams } from "../models/types";

export const useTransaction = (objectId: string | null) => {
    return useQuery([QueryKey.transaction, objectId], async () => {
        if (!objectId || (objectId && objectId === "")) return null;
        const { data } = await getTransaction(objectId);
        return data;
    });
}

export const useTransactions = (params: TransactionFilterParams) => {
    return useQuery([QueryKey.transaction, params], async () => {
        const { data } = await getTransactions(params);
        return data;
    });
}