export default function CourseCardSkeletonLoader() {
    return <div className="col-span-1 flex flex-col max-w-72 min-w-72 divide-y divide-gray-200 rounded-lg bg-white text-center shadow animate-pulse">
        <div className="flex flex-1 flex-col p-8">
            {/* Image Placeholder */}
            <div className="mx-auto h-32 w-32 flex-shrink-0 rounded-full bg-gray-300"></div>

            {/* Title Placeholder */}
            <div className="mt-6 h-4 bg-gray-300 rounded w-3/4 mx-auto"></div>

            {/* Badge Placeholder */}
            <dl className="mt-1 flex flex-grow flex-col justify-between">
                <dt className="sr-only">Role</dt>
                <dd className="mt-3 flex justify-center flex-grow">
                    <div className="h-6 w-20 bg-gray-300 rounded"></div>
                </dd>
            </dl>
        </div>

        {/* Button Placeholder */}
        <div>
            <div className="-mt-px flex divide-x divide-gray-200">
                <div className="flex w-0 flex-1">
                    <div className="cursor-pointer relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 bg-gray-300">
                        <div className="h-5 w-5 bg-gray-400 rounded-full"></div>
                        <div className="h-4 w-20 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}