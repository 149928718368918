import { FunctionComponent, ReactNode, createContext, useContext, useState } from "react";
import { Notification } from "../models/types";

interface IAppContext {
    notification: Notification | null;
    setNotification: (notification: Notification | null) => void;
}

interface AppProviderProps {
    children: ReactNode;
}

export const AppContext = createContext<IAppContext>({
    notification: null,
    setNotification: (notification: Notification | null) => { }
});
export const AppProvider: FunctionComponent<AppProviderProps> = ({ children }) => {
    const [notification, setNotification] = useState<Notification | null>(null);
    return <AppContext.Provider value={{ notification, setNotification }}>{children}</AppContext.Provider>
}

export const useAppContext = () => useContext(AppContext);
