import { useNavigate } from "react-router-dom";

/* eslint-disable jsx-a11y/no-redundant-roles */
export function LandingPageFooter() {
    const navigate = useNavigate();
    const footerNavigation = {
        solutions: [
            { name: 'Área de membros', onClick: () => navigate('/login') },
            { name: '(11) 99358-1886', onClick: () => window.open("https://wa.me/5511993581886?text=Ol%C3%A1,%20tenho%20d%C3%BAvidas%20sobre%20o%20produto", "_blank") },
            { name: 'contato@maisumnabolsa.com.br' },
        ],
    }

    return <footer className="bg-gray-900 mt-36" aria-labelledby="footer-heading">
        <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8 lg:py-10">
            <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                <img
                    className="h-16 w-auto"
                    src="/images/white-logo.png"
                    alt=""
                />
                <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                    <div className="md:grid md:grid-cols-2 md:gap-8">
                        {/* <div className="mt-10 md:mt-0">
                            <h3 className="text-sm font-semibold leading-6 text-white">Legal</h3>
                            <ul role="list" className="mt-6 space-y-4">
                                {footerNavigation?.legal?.map((item) => (
                                    <li key={item.name}>
                                        <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div> */}
                        <div>
                            <h3 className="text-sm font-semibold leading-6 text-white">&copy; Mais um na Bolsa</h3>
                            <ul role="list" className="mt-6 space-y-4">
                                {footerNavigation.solutions.map((item) => (
                                    <li key={item.name}>
                                        <div onClick={() => item.onClick ? item.onClick() : {}} className={`${item.onClick ? "cursor-pointer hover:underline" : ""} text-sm leading-6 text-gray-300 hover:text-white`}>
                                            {item.name}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}