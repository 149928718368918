import { FunctionComponent, ReactNode, createContext, useContext, useState } from "react";
import { User } from "../models/types";

interface IAuthenticationContext {
    user: User | null;
    setUser: (user: User | null) => void;
}


interface AuthenticationProviderProps {
    children: ReactNode;
}

export const AuthenticationContext = createContext<IAuthenticationContext>({
    user: null,
    setUser: (user: User | null) => { }
});
export const AuthenticationProvider: FunctionComponent<AuthenticationProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    return <AuthenticationContext.Provider value={{ user, setUser }}>{children}</AuthenticationContext.Provider>
}

export const useAuthenticationContext = () => useContext(AuthenticationContext);
