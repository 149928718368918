import { useLeads } from "../hooks/useLeads";
import { leadsColumns } from "../pages/shared/LeadsTable";
import BaseTable from "./monthly-budget/Table";

export default function Leads() {

    return <>
        <BaseTable
            useGetData={useLeads}
            title={null}
            onRowClick={(row) => {
            }}
            columns={leadsColumns}
        />
    </>
}