import queryClient, { QueryKey } from "../hooks/state";
import { AIResponse, AISummary } from "../models/types";
import { httpGetAuthenticated } from "./Api";

const endpoints = {
  ai_summary: () => "/central/ai_summary",
  generate: () => `/central/ai`,
  latest_summary: () => `/central/latest_ai_summary`,
};

export const getAISummary = async () => {
  return httpGetAuthenticated<AISummary>(endpoints.ai_summary());
}

export const generateAISummary = async () => {
  const { data } = await httpGetAuthenticated<any>(endpoints.generate());
  queryClient.invalidateQueries(QueryKey.aiSummary);
  return data;
}

export const getLatestAISummary = async () => {
  return httpGetAuthenticated<AIResponse>(endpoints.latest_summary());
}