import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CourseModulesDetail from "../components/CourseModulesDetail";
import CourseProgress from "../components/CourseProgress";
import LoadingSpinner from "../components/Loading";
import { useCourse } from "../hooks/useCourses";

export default function CourseDetail() {
    const navigate = useNavigate()
    const { courseId } = useParams();
    if (!courseId) {
        navigate("/admin")
    }
    const { data: course } = useCourse(courseId ?? "");
    const [openCourse, setOpenCourse] = useState<boolean>(false);
    // const { data: modules } = useCourseModule
    return !course || !courseId ? <LoadingSpinner /> : <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6 flex w-full">
            <div className="flex flex-row flex-grow items-start lg:flex-nowrap xl:flex-nowrap md:flex-nowrap flex-wrap gap-5 overflow-hidden">
                <div className="flex min-w-72 max-w-72 flex-col gap-3 overflow-hidden">
                    <div className="flex-1 w-full max-w-fit px-2 mb-2">
                        <h3 className="text-lg font-medium text-gray-900">{course.title}</h3>
                    </div>
                    <div className="w-72 h-72 max-w-fit shadow border border-gray-200 rounded-xl overflow-hidden">
                        <img src={course.image ?? "/images/no-image.png"} alt={course.title} className="bg-cover" />
                    </div>
                    <div className="px-2 flex flex-col gap-2">
                        <div className="flex flex-row justify-between items-center"></div>
                        <h3 className="text-sm font-medium text-gray-900">Progresso</h3>
                        <CourseProgress progress={course.progress} />
                        <div className="flex flex-row justify-between items-center">
                            <h3 className="text-sm font-medium text-gray-900">Aulas Finalizadas</h3>
                            <div className="text-sm font-medium text-gray-900 text-right">{course.progress.completed}/{course.progress.total}</div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-grow max-w-full w-full px-2 min-w-72 flex-col gap-2 overflow-hidden">
                    <div className="flex-1 w-full px-2 pb-5">
                        <h3 className="text-lg font-medium text-gray-900">Módulos</h3>
                        <p className="text-sm text-gray-500">{course.description}</p>
                    </div>
                    <CourseModulesDetail courseId={courseId} />
                </div>
            </div>
        </div>
    </div>
}