import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { config } from "../config/env";
import { useAuthenticationContext } from "../context/AuthenticationContext";
import { getLogin } from "../services/LoginService";
import { setCookie } from "../utils/cookies";
import LoadingSpinner from "./Loading";

export function Login() {
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { setUser } = useAuthenticationContext();

    const handleLogin = async () => {
        setLoading(true);
        try {
            const response = await getLogin({ username: email, password });
            if (response.status === 200) {
                setCookie("token", response.data.access);
                setCookie("refresh", response.data.refresh);
                navigate("/inicio");
            }
        } catch (e) {
            console.log("error");
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-20 w-auto cursor-pointer"
                        onClick={() => navigate("/home")}
                        src="/images/logo.png"
                        alt="Mais um na Bolsa"
                    />
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Acesse sua conta
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <div className="space-y-6">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    E-mail
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autoComplete="email"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Senha
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        autoComplete="current-password"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <button
                                    onClick={() => handleLogin()}
                                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    {loading ? <LoadingSpinner /> : 'Acessar'}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="mt-10 text-center text-sm text-gray-500 flex items-center justify-center gap-1">
                        Não tem uma conta?{' '}
                        <div onClick={() => window.open(config.payment.kiwify_checkout_url, "_blank")} className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500 cursor-pointer">
                            Assine agora mesmo
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
