import queryClient, { QueryKey } from "../hooks/state";
import { PaymentIntentResponse, User } from "../models/types";
import { PaginatedResponse } from './../models/types';
import { httpGetAuthenticated } from "./Api";

const userEndpoints = {
  user: () => "/central/central/users",
  admin: () => "/admin/users",
};

export const getAllUsers = async () => {
  return httpGetAuthenticated<PaginatedResponse<User>>(userEndpoints.admin());
}

export const getUser = async () => {
  return httpGetAuthenticated<User>(userEndpoints.user());
}

export const getFormCompleted = async () => {
  const response = await httpGetAuthenticated<any>(userEndpoints.user() + "/get_form_completed");
  queryClient.invalidateQueries(QueryKey.users);
  return response;
}

export const getPaymentIntent = async () => {
  return httpGetAuthenticated<PaymentIntentResponse>(userEndpoints.user() + "/payment_intent");
}