import { useQuery } from "react-query";
import { CourseModuleClassFilterParams } from "../models/types";
import { getCourseModuleClass, getCourseModuleClasses } from "../services/CourseModuleClassService";
import { QueryKey } from "./state";

export const useCourseModuleClasses = (params: CourseModuleClassFilterParams) => {
    return useQuery([QueryKey.courseModuleClass, params], async () => {
        const { data } = await getCourseModuleClasses(params);
        return data;
    });
}

export const useCourseModuleClass = (objectId: string | null) => {
    return useQuery([QueryKey.courseModuleClass, objectId], async () => {
        if (!objectId || (objectId && objectId === "")) return null;
        const { data } = await getCourseModuleClass(objectId);
        return data;
    });
}