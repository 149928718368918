import { BaseBadge } from "../../components/Badge";
import CustomSwitch from "../../components/Switch";
import { MonthlyTransaction, TableColumn } from "../../models/types";
import { updateMonthlyTransaction } from "../../services/MonthlyTransactionService";
import { formattedAmount } from "../../utils/helper";

export const transactionColumns: TableColumn<MonthlyTransaction>[] = [
    {
        field: "paid",
        header: "Pago",
        render: (t) => <div onClick={async (e) => {
            e.stopPropagation();
            await updateMonthlyTransaction(t.id, { paid: !t.paid });
        }}><CustomSwitch visible={t.paid} setVisible={() => { }} label="" /></div>,
        className: "w-20 max-w-20 overflow-hidden truncate",
    },
    {
        field: "name",
        header: "Nome",
        render: (t) => <h4 className="text-sm text-gray-900 font-light max-w-full truncate">{t.name}</h4>,
        className: "min-w-40 max-w-20 truncate",
    },
    {
        field: "category_name",
        header: "Categoria",
        className: "hidden md:flex",
        render: (t) =>
            <BaseBadge title={t?.category_name?.name ?? "Sem categoria"} />,
    },
    {
        field: "amount",
        header: "Valor",
        render: (t) =>
            <h4 className="text-sm font-semibold">{formattedAmount(t)}</h4>,
    },
]