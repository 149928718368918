import { useQuery } from "react-query";
import { LeadsFetchParams } from "../models/types";
import { getLeads } from "../services/LeadService";
import { QueryKey } from "./state";

export const useLeads = (params: LeadsFetchParams) => {
    return useQuery([QueryKey.leads, params], async () => {
        const { data } = await getLeads(params);
        return data;
    })
}