import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Button } from "../components/Button";
import { LandingPageFAQ } from "../components/landing-page/main/LandingPageFAQ";
import { LandingPagePriceSection } from "../components/landing-page/main/LandingPagePriceSection";
import LPBenefits from "../components/landing-page/vsl/LandingPageBenefits";
import LandingPageCards from "../components/landing-page/vsl/LandingPageCards";
import OurProcess from "../components/Process";

export default function LPRegister() {
    const ScrollToSection = (sectionId: string) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return <div className="bg-white">
        <div className="flex bg-gray-950 py-24 h-screen justify-center sm:py-12 flex-col flex-grow items-center w-full p-y-10 px-2">
            <div className="absolute inset-0">
                <img
                    alt=""
                    src="/images/bg-lp.png"
                    className="h-full w-full object-cover"
                />
                <div aria-hidden="true" className="absolute inset-0 bg-gray-800 mix-blend-multiply" />
            </div>
            <div className="relative z-10 flex flex-col items-center">
                <span className="mt-6 inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                    Vamos transformar sua vida financeira
                </span>
                <p className="mt-2 max-w-xl text-center text-pretty text-4xl font-medium tracking-tight text-white sm:text-5xl">
                    Do zero a organização financeira em <span className="text-indigo-600">7 dias</span>
                </p>
                <h4 className="pt-4 text-sm text-center max-w-xl text-slate-200">Está cansado de torcer para o dinheiro sobrar no final do mês?<br /> <span className="font-semibold">Veja como podemos ajudar.</span>
                </h4>
                <Button className="text-md font-semibold py-2 px-8 mt-6" title="QUERO SABER MAIS" onClick={() => ScrollToSection("how-to")} />
            </div>
        </div>
        <div id="how-to" className="flex flex-col items-center w-full py-24 px-6 sm:py-32 lg:px-8">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Queremos ajudar você</h2>
            <h2 className="text-3xl text-center font-bold tracking-tight text-gray-900 sm:text-4xl">
                Como funciona?
            </h2>
            <p className="mx-auto text-center mt-3 max-w-xl text-md leading-8 text-gray-600">
                Após ajudar dezenas de pessoas a organizarem suas finanças e caminharem em direção aos seus objetivos, decidimos consolidar todo nosso aprendizado em um método unindo o melhor da tecnologia e do nosso conhecimento.
            </p>
            <OurProcess />
            <Button className="text-md mt-8 font-semibold py-2 px-8 max-w-48" title="Assine Agora" onClick={() => ScrollToSection("offer")} />
        </div>
        <LandingPageCards />
        <LPBenefits />
        <div className="flex flex-col items-center w-full py-24 px-6 sm:py-32 lg:px-8">
            <h2 className="text-3xl text-center font-bold tracking-tight text-gray-900 sm:text-4xl">
                Para quem é nossa plataforma?
            </h2>
            <p className="mx-auto text-center mt-6 max-w-xl text-lg leading-8 text-gray-600">
                Se você ganha mais de três salários mínimos e nunca consegue fazer o dinheiro sobrar, eu venho lhe dizer que você não está sózinho. Saiba que somente ganhar mais não será a solução, você precisa direcionar seu dinheiro de forma inteligente e priorizar seus objetivos.
            </p>
            <p className="mx-auto text-center mt-3 max-w-xl text-lg leading-8 text-indigo-600 font-bold">
                Se você:
            </p>
            <div className="flex flex-col gap-1 items-start">
                <div className="flex flex-row items-center gap-1">
                    <CheckCircleIcon className="h-5 w-5 text-green-800" />
                    <p className="text-lg leading-8 text-gray-600">Não consegue guardar dinheiro</p>
                </div>
                <div className="flex flex-row items-center gap-1">
                    <CheckCircleIcon className="h-5 w-5 text-green-800" />
                    <p className="text-lg leading-8 text-gray-600">Está em busca de um plano prático</p>
                </div>
                <div className="flex flex-row items-center gap-1">
                    <CheckCircleIcon className="h-5 w-5 text-green-800" />
                    <p className="text-lg leading-8 text-gray-600">Deseja ter controle do seu dinheiro</p>
                </div>
                <div className="flex flex-row items-center gap-1">
                    <CheckCircleIcon className="h-5 w-5 text-green-800" />
                    <p className="text-lg leading-8 text-gray-600">Quer atingir suas metas financeiras</p>
                </div>
            </div>
            <p className="mx-auto text-center mt-3 max-w-xl text-lg leading-8 text-indigo-600 font-bold">
                Nós podemos te ajudar!
            </p>
        </div>
        <LandingPagePriceSection />
        <LandingPageFAQ />
        <div className="flex flex-col py-10 bg-gray-900 text-white text-center items-center flex-grow">
            <h4 className="text-white text-sm">Todos os direitos reservados.</h4>
            <h4 className="text-white text-sm">&copy; Mais um na Bolsa 2024</h4>
        </div>
        <div className="fixed z-50 bottom-6 right-6 cursor-pointer" onClick={() => window.open("https://wa.me/5511993581886?text=Ol%C3%A1,%20tenho%20d%C3%BAvidas%20sobre%20o%20produto", "_blank")}>
            <img src="/images/wpp.png" alt="whatsapp" className="w-16 h-16 rounded-full" />
        </div>
    </div>
}