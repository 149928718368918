import { Switch } from "@headlessui/react";

type CustomSwitchProps = {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    label: string;
}

export default function CustomSwitch({ visible, setVisible, label }: CustomSwitchProps) {
    return <Switch
        checked={visible}
        onChange={setVisible}
        className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
    >
        <span className="sr-only">{label}</span>
        <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
        <span
            aria-hidden="true"
            className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out group-data-[checked]:bg-indigo-600"
        />
        <span
            aria-hidden="true"
            className="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
        />
    </Switch>
}