import { format } from "date-fns";
import { EvaluateStock } from "../models/types";
import { BalanceChart } from "./charts/BalanceChart";
import { DividendsChart } from "./charts/DividendsChart";

export const StockEvaluation = ({ evaluation }: { evaluation: EvaluateStock }) => {
    const stats = [
        { name: 'Aportes', value: `R$ ${evaluation?.total_spent}`, change: '', changeType: '' },
        { name: 'Rendimentos Recebidos', value: `R$ ${evaluation.total_dividends}`, change: '', changeType: '' },
        { name: 'Saldo de Cotas', value: evaluation.stocks_amount, change: '', changeType: '' },
        { name: 'Patrimônio', value: `R$ ${(evaluation.last_price * evaluation.stocks_amount).toFixed(2)}`, change: `R$ ${evaluation.last_price}/cota`, changeType: 'positive' },
    ]
    function classNames(...classes: string[]): string {
        return classes.filter(Boolean).join(' ')
    }
    return <div className="flex flex-col gap-3">
        <div className="bg-white shadow">
            <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4">
                {stats.map((stat) => (
                    <div
                        key={stat.name}
                        className="flex flex-wrap items-baseline bg-white justify-between gap-x-4 gap-y-2px-4 py-10 sm:px-6 xl:px-8"
                    >
                        <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
                        <dd
                            className={classNames(
                                stat.changeType === 'negative' ? 'text-rose-600' : 'text-gray-700',
                                'text-xs font-medium',
                            )}
                        >
                            {stat.change}
                        </dd>
                        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                            {stat.value}
                        </dd>
                    </div>
                ))}
            </dl>
        </div>
        <div className="w-full flex flex-row sm:flex-col md:flex-row gap-2">

            <div className="bg-white shadow sm:rounded-lg w-1/2 sm:w-full md:w-1/2">
                <div className="w-full px-4 py-5 sm:p-6 flex justify-center flex-col items-center gap-2 h-auto">
                    <h3 className="w-full text-left text-base font-semibold leading-6 text-gray-900">Evolução dos dividendos</h3>
                    <div className="w-full text-left text-sm text-gray-500">
                        <p>
                            Rendimentos mensais considerando o reinvestimento dos dividendos junto ao aporte mensal.
                        </p>
                    </div>
                    <DividendsChart
                        labels={evaluation.owned_stocks.map((os) => format(os.date, "MM/yy"))}
                        dividends={evaluation.owned_stocks.map((os) => os.dividends)}
                    />
                </div>
            </div>
            <div className="bg-white shadow sm:rounded-lg w-1/2 sm:w-full md:w-1/2">
                <div className="w-full px-4 py-5 sm:p-6 flex justify-center flex-col items-center gap-2">
                    <h3 className="w-full text-left text-base font-semibold leading-6 text-gray-900">Evolução Patrimônial</h3>
                    <div className="w-full text-left text-sm text-gray-500">
                        <p>
                            Evolução do patrimônio com reinvestimento dos dividendos
                        </p>
                    </div>
                    <BalanceChart
                        labels={evaluation.owned_stocks.map((os) => format(os.date, "MM/yy"))}
                        dividends={evaluation.owned_stocks.map((os) => os.balance)}
                    />
                </div>
            </div>
        </div>
    </div>
}