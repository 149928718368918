import queryClient, { QueryKey } from "../hooks/state";
import { CreateTransactionCategory, TransactionCategory, TransactionCategoryFilterParams } from "../models/types";
import { PaginatedResponse } from './../models/types';
import { httpDeleteAuthenticated, httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./Api";

const endpoints = {
  list: () => "/central/central/transaction_categories",
  retrieve: (id: string) => `/central/central/transaction_categories/${id}`,
};

export const getTransactionCategory = async (objectId: string) => {
  return httpGetAuthenticated<TransactionCategory>(endpoints.retrieve(objectId));
}

export const getTransactionCategories = async (params: TransactionCategoryFilterParams) => {
  return httpGetAuthenticated<PaginatedResponse<TransactionCategory>>(endpoints.list(), {
    params,
  });
}

export const createTransactionCategory = async (payload: CreateTransactionCategory) => {
  const response = await httpPostAuthenticated<TransactionCategory>(endpoints.list(), payload);
  queryClient.invalidateQueries(QueryKey.transactionCategory);
  return response.data;
}

export const updateTransactionCategory = async (id: string, payload: CreateTransactionCategory) => {
  const response = await httpPatchAuthenticated(endpoints.retrieve(id), payload);
  queryClient.invalidateQueries(QueryKey.transactionCategory);
  return response.data;
}

export const deleteTransactionCategory = async (id: string) => {
  const response = await httpDeleteAuthenticated(endpoints.retrieve(id));
  queryClient.invalidateQueries(QueryKey.transactionCategory);
  return response.data;
}