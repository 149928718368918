import { Transaction, TransactionCategory } from "../models/types";
import { formatBrazilianCurrence } from "../utils/helper";
import { EditIcon } from "./Button";
type GroupedTransactionProps = {
    category: TransactionCategory;
    transactions: Transaction[];
    total: number;
    onSelectTransaction: (transactionId: string) => void;
}
export const GroupedTransaction = ({
    category,
    transactions,
    total,
    onSelectTransaction,
}: GroupedTransactionProps) => {
    return <div
        className="flex flex-col rounded-md border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 gap-0"
    >
        <div className='flex p-2 flex-row gap-2 items-center '>
            <div className="flex w-full flex-row justify-between items-center">
                <h4 className="block text-sm text-gray-900 font-bold">{category.name}</h4>
                <div className='flex flex-row gap-1'>
                    <div className="flex flex-col">
                        {category.budget_amount && <h4 className="flex flex-grow text-xs w-22 text-gray-400">
                            Orçamento:
                        </h4>}
                        <h4 className="text-xs text-red-400 flex flex-grow">
                            Despesa Total:
                        </h4>
                    </div>
                    <div className="flex flex-col">
                        {category.budget_amount && <h4 className="flex flex-grow text-xs w-22 text-gray-400">
                            R${category.budget_amount}
                        </h4>}
                        <h4 className="text-xs text-red-400 flex flex-grow">
                            R${total.toFixed(2)}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex w-full border-t border-gray-300"></div>
        <div className='flex flex-col w-full'>
            {transactions.map((transaction, idx) => <div
                onClick={() => onSelectTransaction(transaction.id)}
                className={`flex px-2 py-3 ${idx !== (transactions.length - 1) ? "border-b border-gray-300" : ""} flex-row justify-between w-full items-center hover:bg-gray-100 cursor-pointer`}>
                <div className="pl-1 py-1 flex flex-row items-center gap-1 w-full max-w-[90%] overflow-hidden truncate">
                    <EditIcon className="text-gray-700 h-4 w-4 min-w-4 min-h-4" onClick={() => { }} />
                    <h4 className="text-xs text-gray-700 truncate">{transaction.name}</h4>
                </div>
                <h4 className="pr-1 text-xs text-red-400">R${formatBrazilianCurrence(transaction.amount)}</h4>
            </div>)}
        </div>
    </div>
}