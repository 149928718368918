import { TableColumn, User } from "../../models/types";

export const usersColumns: TableColumn<User>[] = [
    {
        field: "name",
        header: "Name",
        render: (t) => <h4>{t.name}</h4>,
    },
    {
        field: "email",
        header: "E-mail",
        render: (t) => <h4>{t.email}</h4>,
    },
]