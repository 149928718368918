import { XMarkIcon } from "@heroicons/react/24/outline";
import PrivacyPolicy from "../PrivacyPolicy";
import BaseModal from "./BaseModal";

type PrivacyPolicyModalProps = {
    open: boolean;
    onClose: () => void;
}

export default function PrivacyPolicyModal({ open, onClose }: PrivacyPolicyModalProps) {

    return (
        <BaseModal open={open} onClose={onClose}>
            <div className="flex items-center justify-between w-full">
                <h4 className="text-lg font-bold">Política de Privacidade</h4>
                <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={onClose} />
            </div>
            <PrivacyPolicy />
        </BaseModal>
    )
}
