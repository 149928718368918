import {
    PlusIcon
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { useState } from 'react';
import { Loader } from '../components/Loader';
import StockEvaluationModal from '../components/modals/StockEvaluationModal';
import { StockEvaluation, } from '../components/StockEvaluation';
import { useStockEvaluation } from '../hooks/useStockEvaluation';
import { EvaluateStockBody } from '../models/types';


export default function SimulationPage() {
    const [params, setParams] = useState<EvaluateStockBody | null>(null);

    const { data, isLoading } = useStockEvaluation(params);
    const [openSimulationModal, setOpenSimulationModal] = useState(false);

    const stats = [
        { name: 'Ativo', stat: params?.ticker_name },
        { name: 'Primeira Transação', stat: params ? format(`${params?.first_transaction} 00:00:00`, "dd/MM/yyyy") : '' },
        { name: 'Aporte Mensal', stat: `R$ ${params?.initial_amount}` },
    ]

    return <div className="flex flex-col gap-3">
        {!params && <div className="bg-white shadow sm:rounded-lg py-5 w-full">
            <EmptyState onOpen={() => setOpenSimulationModal(true)} />
        </div>}
        {params && <div>
            <div className='flex gap-2 flex-row items-center justify-between'>
                <h3 className="text-base font-semibold leading-6 text-gray-900">Simulação</h3>
                <button className="text-xs underline text-blue-500" onClick={() => setParams(null)}>Nova simulação</button>
            </div>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                {stats.map((item) => (
                    <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                        <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                    </div>
                ))}
            </dl>
        </div>}
        <Loader loading={isLoading} />
        {data && <StockEvaluation evaluation={data} />}
        <StockEvaluationModal open={openSimulationModal} onChange={setParams} onClose={() => setOpenSimulationModal(false)} />
    </div>
}

export const EmptyState = ({ onOpen }: { onOpen: () => void; }) => {
    return (
        <div className="text-center">
            <svg
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
                className="mx-auto h-12 w-12 text-gray-400"
            >
                <path
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    strokeWidth={2}
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">Nenhuma simulação em andamento</h3>
            <p className="mt-1 text-sm text-gray-500">Avaliação de cenários passados utilizando dados de mercado</p>
            <div className="mt-6">
                <button
                    type="button"
                    onClick={() => onOpen()}
                    className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5" />
                    Nova Simulação
                </button>
            </div>
        </div>
    )
}