import { useQuery } from "react-query";
import { MonthlyTransactionFetchParams } from "../models/types";
import { QueryKey } from "./state";
import { getMonthlyTransaction, getMonthlyTransactions } from "../services/MonthlyTransactionService";

export const useMonthlyTransaction = (objectId: string | null) => {
    return useQuery([QueryKey.monthlyTransaction, objectId], async () => {
        if (!objectId || (objectId && objectId === "")) return null;
        const { data } = await getMonthlyTransaction(objectId);
        return data;
    });
}

export const useMonthlyTransactions = (params: MonthlyTransactionFetchParams) => {
    return useQuery([QueryKey.monthlyTransaction, params], async () => {
        const { data } = await getMonthlyTransactions(params);
        return data;
    });
}