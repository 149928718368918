import { useQuery } from "react-query";
import { FileFilterParams } from "../models/types";
import { getFile, getFiles } from "../services/FileService";
import { QueryKey } from "./state";

export const useFiles = (params: FileFilterParams) => {
    return useQuery([QueryKey.file, params], async () => {
        const { data } = await getFiles(params);
        return data;
    });
}

export const useFile = (objectId: string | null) => {
    return useQuery([QueryKey.file, objectId], async () => {
        if (!objectId || (objectId && objectId === "")) return null;
        const { data } = await getFile(objectId);
        return data;
    });
}