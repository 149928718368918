import { CheckIcon, LockClosedIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CourseModulesTable from "../components/CourseModules";
import LoadingSpinner from "../components/Loading";
import CourseCreationModal from "../components/modals/CourseCreationModal";
import { useCourse } from "../hooks/useCourses";

export default function AdminCoursePage() {
    const navigate = useNavigate()
    const { id } = useParams();
    if (!id) {
        navigate("/admin")
    }
    const { data: course } = useCourse(id ?? "");
    const [openCourse, setOpenCourse] = useState<boolean>(false);
    // const { data: modules } = useCourseModule
    return !course || !id ? <LoadingSpinner /> : <div className="bg-white shadow sm:rounded-lg">
        <CourseCreationModal courseId={id} onClose={() => setOpenCourse(false)} open={openCourse} />
        <div className="px-4 py-5 sm:p-6">
            <div className="flex flex-row gap-2 items-center justify-between">
                <div className="flex flex-row gap-2 items-center">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">{course.title}</h3>
                    {course.visible &&
                        <div className="flex flex-row items-center">
                            <CheckIcon className="h-3 w-3 flex-shrink-0 text-green-600" />
                            <h3 className="text-xs text-green-600">Visível</h3>
                        </div>}
                    {!course.visible &&
                        <div className="flex flex-row items-center">
                            <LockClosedIcon className="h-3 w-3 flex-shrink-0 text-red-600" />
                            <h3 className="text-xs text-red-600">Bloqueado</h3>
                        </div>}
                </div>
                <PencilSquareIcon onClick={() => setOpenCourse(true)} className="h-5 w-5 flex-shrink-0 text-indigo-500 cursor-pointer" />
            </div>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>
                    {course.description}
                </p>
            </div>
            <CourseModulesTable courseId={id} />
        </div>
    </div>
}