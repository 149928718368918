import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { APIResponse } from "../models/types";
import CourseCardSkeletonLoader from "./skeleton/CourseCardSkeleton";

type PaginatedCardProps<T, P> = {
    params: P;
    render: (obj: T) => React.ReactNode;
    useGetData: APIResponse<T, P>;
    pageSize?: number;
    emptyComponent?: React.ReactNode;
}

export default function PaginatedCard<T, P>({
    params, render, useGetData, pageSize, emptyComponent
}: PaginatedCardProps<T, P>) {
    const [page, setPage] = useState(1);
    const default_page_size = 10;
    const { data, isLoading, isFetching } = useGetData({ ...params, page_size: pageSize ?? default_page_size, page });

    const hasNextPage = data?.next !== null;
    const hasPreviousPage = data?.previous !== null;

    return <div className="w-full flex-col sm:flex-row overflow-hidden flex items-center box-border">
        {/* Left Arrow */}
        <ArrowLeftCircleIcon
            onClick={() => hasPreviousPage && setPage(page - 1)}
            className={`hidden sm:block h-9 w-9 ${hasPreviousPage ? "cursor-pointer text-indigo-700" : "text-gray-200"}`}
        />

        {/* Cards container with scrolling */}
        <div className="flex flex-col py-2 md:flex-row max-w-full w-full gap-4 items-center overflow-x-auto flex-wrap md:flex-nowrap scrollbar-hide md:px-4 md:py-2">
            {/* Repeat skeleton loader based on page size count*/}
            {(isLoading || isFetching) && Array.from({ length: pageSize ?? default_page_size }).map((_, index) => <CourseCardSkeletonLoader key={index} />)}
            {data?.results?.map((obj: T) => render(obj))}
            {data?.results && data?.results.length === 0 && emptyComponent}
        </div>

        {/* Right Arrow */}
        <ArrowRightCircleIcon
            onClick={() => hasNextPage && setPage(page + 1)}
            className={`hidden sm:block h-9 w-9 ${hasNextPage ? "cursor-pointer text-indigo-700" : "text-gray-200"}`}
        />
        <div className="flex flex-row items-center sm:hidden">
            <ArrowLeftCircleIcon
                onClick={() => hasPreviousPage && setPage(page - 1)}
                className={`h-9 w-9 ${hasPreviousPage ? "cursor-pointer text-indigo-700" : "text-gray-200"}`}
            />
            <ArrowRightCircleIcon
                onClick={() => hasNextPage && setPage(page + 1)}
                className={`h-9 w-9 ${hasNextPage ? "cursor-pointer text-indigo-700" : "text-gray-200"}`}
            />
        </div>
    </div>
}