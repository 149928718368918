import { useLatestAISummary } from "../hooks/useAI";
import { AIExpenseResponse as AIExpenseResponseType } from "../models/types";

export function AIExpenseDetail({ expense }: { expense: AIExpenseResponseType }) {
    return <div className="flex w-full flex-col max-w-96">
        <div className="flex flex-row items-center w-full justify-between">
            <div className="flex flex-row items-center gap-2">
                <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">{expense.name}</span>
                </p>
            </div>
            <h4 className="text-xs flex font-light text-gray-900">R${expense.amount}</h4>
        </div>
        <AIExpenseResponse detail={expense.recommendation} />
    </div>
}

export function AIExpenseResponse({ detail }: { detail: string }) {
    return <div className="ml-0.5 border-l p-1 gap-4 border-gray-300 flex flex-row items-start">
        <div className="flex flex-row items-start flex-grow gap-4 py-3">
            <img
                alt=""
                src="/images/ai.png"
                className="h-6 w-6 flex-none rounded-full bg-gray-50"
            />
            <div className="border flex flex-col border-gray-300 rounded-md max-w-96 flex-grow h-full py-2 px-2 gap-1">
                <h4 className="text-xs text-gray-600 font-bold">Alberto <span className="font-light text-gray-600">sugeriu:</span></h4>
                <h4 className="text-xs text-gray-900 font-light">
                    {detail}
                </h4>
            </div>
        </div>
    </div>
}

export default function AISummaryDetail() {
    const { data: summary } = useLatestAISummary();
    return summary ? <div className="flex flex-grow flex-col gap-4 p-2">
        <h4 className="text-gray-900 text-sm font-semibold">Sugestões práticas para o seu orçamento</h4>
        <div className="flex flex-row items-start flex-grow gap-4 py-3">
            <img
                alt=""
                src="/images/ai.png"
                className="h-6 w-6 flex-none rounded-full bg-gray-50"
            />
            <div className="border flex flex-col border-gray-300 rounded-md h-full py-2 px-2 gap-1">
                <h4 className="text-xs text-gray-600 font-bold">Alberto <span className="font-light text-gray-600">sugeriu:</span></h4>
                <h4 className="text-xs text-gray-900 font-light">
                    {summary.summary.practical_actions.map((action, index) => <div className="flex flex-col gap-1 py-2" key={index}>
                        <p className="text-xs text-gray-900 font-semibold">{action.name}</p>
                        <p className="text-xs text-gray-900 font-light">{action.description}</p>
                    </div>)}
                </h4>
            </div>
        </div>
        <h4 className="text-gray-900 text-sm font-semibold">Avaliação das suas despesas mensais</h4>
        <div className="flex flex-grow flex-row flex-wrap gap-x-8">
            {summary.summary.expenses.map((expense, index) => <AIExpenseDetail key={index} expense={expense} />)}
        </div>

    </div> : null;
}