import { PresentationChartBarIcon } from "@heroicons/react/24/outline";
import { differenceInMonths, format, parseISO } from "date-fns";
import { Goal } from "../models/types";
import { formatToBrazilianCurrency } from "../utils/handleMoneyChange";
import BaseCard from "./BaseCard";
import CourseProgress from "./CourseProgress";

export default function GoalCard({ goal, onEdit }: { goal: Goal, onEdit: (goalId: string) => void }) {
    const calculatePendingValuePerMonth = (goal: Goal) => {
        const goalDate = parseISO(goal.goal_date);
        const currentDate = new Date();
        const months = differenceInMonths(goalDate, currentDate);
        const pendingValue = goal.goal_amount - goal.amount;
        if (pendingValue < 0) return 0;
        const result = (pendingValue / months).toFixed(2)
        return result;
    }

    const handleToolTipLabel = (goal: Goal) => {
        const pendingValue = calculatePendingValuePerMonth(goal);
        if (pendingValue === 0) return "Parabéns, você atingiu sua meta!";
        return `Guarde R$${formatToBrazilianCurrency(pendingValue)}/mês para atingir`
    }
    return <BaseCard
        key={goal.id}
        className="max-w-80 min-w-72"
        icon={<PresentationChartBarIcon className="h-6 w-6 min-h-6 min-w-6 text-indigo-700" />}
        title={goal.name}
        content={<div className="flex flex-col flex-grow px-2">
            <div className="border-b border-gray-300 mb-1 flex flex-row items-center justify-between truncate">
                <span className="text-sm leading-7 font-light">Data</span>
                <span className="text-sm leading-7 font-light">{format(parseISO(goal.goal_date), "dd/MM/yyyy")}</span>
            </div>
            <div className="border-b border-gray-300 mb-1 flex flex-row items-center justify-between truncate">
                <span className="text-sm leading-7 font-light">Meta</span>
                <span className="text-sm leading-7 font-light">R$ {formatToBrazilianCurrency(`${goal.goal_amount}`)}</span>
            </div>
            <div className="border-b border-gray-300 mb-1 flex flex-row items-center justify-between truncate">
                <span className="text-sm leading-7 font-light">Acumulado</span>
                <div className="flex flex-row items-center gap-2">
                    <span className="text-sm leading-7 font-light text-green-700">R$ {formatToBrazilianCurrency(`${goal.amount}`)}</span>
                </div>
            </div>
            <div className="flex flex-col gap-1 my-2">
                <CourseProgress progress={{
                    completed: goal.amount,
                    total: goal.goal_amount,
                    is_completed: goal.progress.is_completed,
                }} />
                <h4 className="text-xs text-gray-500">
                    {handleToolTipLabel(goal)}
                </h4>
            </div>
        </div>}
        onClick={() => {
            onEdit(goal.id);
        }} />
}