import { Switch } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { useFile } from "../../hooks/useFiles";
import { createFile, deleteFile, updateFile } from "../../services/FileService";
import { CustomInput } from "../CustomInput";
import BaseModal from "./BaseModal";
import ConfirmationModal from "./ConfirmationModal";

type FileCreationModalProps = {
    open: boolean;
    onClose: () => void;
    fileId: string | null;
}

export default function FileCreationModal({ open, onClose, fileId }: FileCreationModalProps) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [free, setFree] = useState<boolean>(false);
    const [category, setCategory] = useState<string>("");
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const navigate = useNavigate();
    const { data: file, isLoading } = useFile(fileId);

    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const { setNotification } = useAppContext();

    useEffect(() => {
        if (file) {
            setName(file.name);
            setDescription(file.description ?? "");
            setFree(file.free_download);
            setCategory(file.category);
        } else {
            setName("");
            setCategory("");
            setDescription("");
            setFree(false);
            setUploadedFile(null);
        }
    }, [file, open])

    const handleFileChange = (e: any) => {
        const newFile = e.target.files[0];
        setUploadedFile(newFile);
    };

    const handleDeleteFile = async () => {
        try {
            if (fileId) {
                await deleteFile(fileId);
                setNotification({
                    type: 'success',
                    message: 'Material excluido com sucesso',
                    title: 'Material excluido'
                })
                onClose();
                setOpenConfirmation(false);
            } else {
                setNotification({
                    type: 'error',
                    message: 'Erro ao excluir material',
                    title: 'Erro'
                })
            }
        } catch (error) {
            setNotification({
                type: 'error',
                message: 'Tente novamente',
                title: 'Erro ao excluir material'
            })
        }
    }

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (name === "") {
                return;
            }
            if (description === "") {
                return;
            }
            if (fileId) {
                await updateFile(fileId, {
                    name: name,
                    description,
                    free_download: free,
                    category,
                })
            } else {
                await createFile({
                    name,
                    description,
                    category,
                    free_download: free,
                    file: uploadedFile,
                })
            }
            onClose();
            setNotification({
                type: 'success',
                message: `Material ${fileId ? "editado" : "adicionado"} com sucesso`,
                title: `Material`
            })
        } catch (error) {
            setNotification({
                type: 'error',
                message: `Erro ao ${fileId ? "editar" : "adicionar"} o material, tente novamente`,
                title: `Material`
            })
        } finally {
            setLoading(false);
        }
    }

    return (
        <BaseModal open={open} onClose={onClose}>
            <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold">{fileId ? "Editar Material" : "Criar Material"}</h2>
                    <div className="flex items-center gap-2">
                        {fileId && <span onClick={() => setOpenConfirmation(true)} className={`materialr-pointer inline-flex items-center bg-red-300 gap-x-1.5 rounded-md px-2 py-1 text-xs font-light text-red-900 ring-1 ring-inset ring-gray-200 hover:ring-gray-300 hover:bg-red-400 hover:text-white`}>
                            Excluir
                        </span>}
                        <XMarkIcon onClick={() => onClose()} className="h-5 w-5 materialr-pointer" />
                    </div>
                </div>
                <CustomInput title="Titulo"
                    description="Informe um titulo para o material"
                    error={""}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    value={name}
                    placeholder="Titulo do material"
                    name="material-name"
                    id="material-name"
                    type="text"
                />
                <CustomInput title="Categoria"
                    description="Informe uma categoria para o material"
                    error={""}
                    onChange={(e) => {
                        setCategory(e.target.value)
                    }}
                    value={category}
                    placeholder="Categoria do material"
                    name="material-name"
                    id="material-name"
                    type="text"
                />
                <CustomInput title="Descrição"
                    description="Informe uma descrição para o material"
                    error={""}
                    onChange={(e) => {
                        setDescription(e.target.value)
                    }}
                    value={description}
                    placeholder="Descrição do material"
                    name="material-description"
                    id="description-name"
                    type="text"
                />
                <h3 className="text-base font-semibold leading-6 text-gray-900">Material Gratuito</h3>
                <Switch
                    checked={free}
                    onChange={setFree}
                    className="group relative inline-flex h-5 w-10 flex-shrink-0 materialr-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                >
                    <span className="sr-only">Use setting</span>
                    <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                    <span
                        aria-hidden="true"
                        className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out group-data-[checked]:bg-indigo-600"
                    />
                    <span
                        aria-hidden="true"
                        className="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                    />
                </Switch>
                {!fileId && <>
                    <h3 className="text-base font-semibold leading-6 text-gray-900">Arquivo</h3>
                    <div className="flex flex-col gap-2 items-center">
                        <input
                            type="file"
                            accept="*"
                            onChange={handleFileChange}
                            className="text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-100 file:text-blue-700 hover:file:bg-blue-200 mt-1"
                        />
                    </div>
                </>}
                <button disabled={loading} onClick={() => {
                    handleSubmit();
                }} className="bg-indigo-600 hover:bg-indigo-700 rounded-md text-white text-sm font-medium py-1">{fileId ? "Salvar Alterações" : "Criar Material"}</button>
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    onConfirm={() => handleDeleteFile()}
                    onCancel={() => setOpenConfirmation(false)}
                    title="Excluir Meta"
                    message={`Deseja realmente excluir o material ${file?.name}`} />
            </div>
        </BaseModal>
    )
}
