import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import { APIResponse, TableColumn } from "../../models/types";
import EmptyTable from "../EmptyTable";

type BasicTableProps<T, P> = {
    params?: P;
    useGetData: APIResponse<T, P>;
    columns: TableColumn<T>[];
    title?: React.ReactNode;
    onAdd?: () => void;
    onAddTitle?: string;
    onRowClick?: (row: T) => void;
    rowClassName?: (row: T) => string;
}

export default function BaseTable<T, P>({
    columns,
    useGetData,
    title,
    onAdd,
    onAddTitle,
    rowClassName,
    params,
    onRowClick,
}: BasicTableProps<T, P>) {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const { data: rows, isLoading, isFetching } = useGetData({ ...params, page_size: pageSize, page } as any);
    const hasNext = !!rows?.next;
    const hasPrevious = !!rows?.previous;
    return (
        <div>
            <div className="sm:flex sm:items-center">
                {<div className="sm:flex-auto">
                    {title}
                </div>}
                {onAdd && <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        type="button"
                        onClick={() => onAdd && onAdd()}
                        className="block rounded-md bg-indigo-600 px-4 py-1 text-center text-md text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        {`Adicionar ${onAddTitle ? onAddTitle : ""}`}
                    </button>
                </div>}
            </div>
            <div className={`${(title || onAdd) ? "mt-8" : ""} flow-root`}>
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        {columns.map((column, index) =>
                                            <th key={index} scope="col" className={twMerge("py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6", column?.className ?? "")}>
                                                {column.header}
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody className={`divide-y divide-gray-200 bg-white`}>
                                    {rows?.results && rows?.results?.length === 0 &&
                                        <tr>
                                            <td colSpan={columns.length}>
                                                <EmptyTable />
                                            </td>
                                        </tr>}
                                    {rows?.results?.map((row, index) => (
                                        <tr onClick={() => onRowClick && onRowClick(row)} key={index} className={twMerge(`${onRowClick ? "cursor-pointer hover:bg-gray-50" : ""}`, rowClassName ? rowClassName(row) : "")}>
                                            {columns.map((column, index) => {
                                                return <td key={index} className={twMerge(`whitespace-nowrap py-2 pl-4 pr-3 sm:pl-6`, column?.className ?? "")}>
                                                    {column.render(row)}
                                                </td>
                                            }
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-grow justify-end gap-1 mt-3">
                {/* <PageSize size={pageSize} setSize={setPageSize} /> */}
                <ArrowLeftCircleIcon
                    onClick={() => hasPrevious && setPage(page - 1)}
                    className={`h-7 w-7 ${hasPrevious ? "cursor-pointer text-blue-500" : "text-gray-300"}`} />
                <ArrowRightCircleIcon
                    onClick={() => hasNext && setPage(page + 1)}
                    className={`h-7 w-7 ${hasNext ? "cursor-pointer text-blue-500" : "text-gray-300"}`} />
            </div>
        </div>
    )
}