import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { useCourseModule } from "../../hooks/useCourseModules";
import { createCourseModule, deleteCourseModule, updateCourseModule } from "../../services/CourseModuleService";
import { CustomInput } from "../CustomInput";
import BaseModal from "./BaseModal";
import ConfirmationModal from "./ConfirmationModal";

type CourseModuleCreationModalProps = {
    open: boolean;
    onClose: () => void;
    courseId: string;
    moduleId: string | null;
}

export default function CourseModuleCreationModal({ open, onClose, courseId, moduleId }: CourseModuleCreationModalProps) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [moduleNumber, setModuleNumber] = useState<string>("1");
    const navigate = useNavigate();

    const { data: module, isLoading } = useCourseModule(moduleId);

    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const { setNotification } = useAppContext();

    useEffect(() => {
        if (module) {
            setName(module.title);
            setDescription(module.description)
            setModuleNumber(`${module.module_number}`)
        } else {
            setName("");
            setDescription("");
            setModuleNumber("0");
        }
    }, [module, open])

    const handleDeleteCourse = async () => {
        try {
            if (moduleId) {
                await deleteCourseModule(moduleId);
                setNotification({
                    type: 'success',
                    message: 'modulo excluido com sucesso',
                    title: 'modulo excluido'
                })
                navigate("/admin")
                onClose();
                setOpenConfirmation(false);
            } else {
                setNotification({
                    type: 'error',
                    message: 'Nenhum modulo selecionado',
                    title: 'Erro ao excluir modulo'
                })
            }
        } catch (error) {
            setNotification({
                type: 'error',
                message: 'Tente novamente',
                title: 'Erro ao excluir modulo'
            })
        }
    }

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (name === "") {
                return;
            }
            if (description === "") {
                return;
            }
            if (moduleId) {
                await updateCourseModule(moduleId, {
                    title: name,
                    description,
                    module_number: Number(moduleNumber),
                    course: courseId,
                })
            } else {
                await createCourseModule({
                    title: name,
                    description,
                    module_number: Number(moduleNumber),
                    course: courseId,
                })
            }
            onClose();
            setNotification({
                type: 'success',
                message: `Modulo ${moduleId ? "editado" : "adicionado"} com sucesso`,
                title: `Modulo`
            })
        } catch (error) {
            setNotification({
                type: 'error',
                message: `Erro ao ${moduleId ? "editar" : "adicionar"} o Modulo, tente novamente`,
                title: `Modulo`
            })
        } finally {
            setLoading(false);
        }
    }

    return (
        <BaseModal open={open} onClose={onClose}>
            <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold">{moduleId ? "Editar Modulo" : "Criar Modulo"}</h2>
                    <div className="flex items-center gap-2">
                        {moduleId && <span onClick={() => setOpenConfirmation(true)} className={`cursor-pointer inline-flex items-center bg-red-300 gap-x-1.5 rounded-md px-2 py-1 text-xs font-light text-red-900 ring-1 ring-inset ring-gray-200 hover:ring-gray-300 hover:bg-red-400 hover:text-white`}>
                            Excluir
                        </span>}
                        <XMarkIcon onClick={() => onClose()} className="h-5 w-5 cursor-pointer" />
                    </div>
                </div>
                <CustomInput title="Titulo"
                    description="Informe um titulo para o modulo"
                    error={""}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    value={name}
                    placeholder="Titulo do modulo"
                    name="course-name"
                    id="course-name"
                    type="text"
                />
                <CustomInput title="Descrição"
                    description="Informe uma descrição para o modulo"
                    error={""}
                    onChange={(e) => {
                        setDescription(e.target.value)
                    }}
                    value={description}
                    placeholder="Descrição do modulo"
                    name="course-description"
                    id="description-name"
                    type="text"
                />
                <CustomInput title="Número do Módulo"
                    description="O número do módulo serve para ordenação em que ele aparecerá para o usuário"
                    error={""}
                    onChange={(e) => {
                        setModuleNumber(e.target.value)
                    }}
                    value={moduleNumber}
                    placeholder="Número do Módulo"
                    name="module-description"
                    id="module-description-name"
                    type="text"
                />

                <button disabled={loading} onClick={() => {
                    handleSubmit();
                }} className="bg-indigo-600 hover:bg-indigo-700 rounded-md text-white text-sm font-medium py-1">{moduleId ? "Salvar Alterações" : "Criar Módulo"}</button>
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    onConfirm={() => handleDeleteCourse()}
                    onCancel={() => setOpenConfirmation(false)}
                    title="Excluir Meta"
                    message={`Deseja realmente excluir o modulo ${module?.title}`} />
            </div>
        </BaseModal>
    )
}
