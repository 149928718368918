import { useNavigate } from "react-router-dom";
import { Button } from "../../Button";

export function LandingPageHeroSection() {

    const navigate = useNavigate();
    const ScrollToSection = (sectionId: string) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return <div className="flex bg-gray-950 py-24 h-screen justify-center sm:py-12 flex-col flex-grow items-center w-full p-y-10 px-2">
        <div className="absolute inset-0">
            <img
                alt=""
                src="/images/bg-lp.png"
                className="h-full w-full object-cover"
            />
            <div aria-hidden="true" className="absolute inset-0 bg-gray-800 mix-blend-multiply" />
        </div>
        <div className="relative z-10 flex flex-col items-center">
            <span className="mt-6 inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                Vamos transformar sua vida financeira
            </span>
            <p className="mt-2 max-w-xl text-center text-pretty text-4xl font-medium tracking-tight text-white sm:text-5xl">
                Do zero a organização financeira em <span className="text-indigo-600">7 dias</span>
            </p>
            <div className="flex mt-8 flex-row items-center gap-6">
                <Button className="text-sm font-semibold py-2 px-4" title="Saiba como" onClick={() => ScrollToSection("how-to")} />
                <Button className="text-md font-semibold text-white bg-[none] hover:underline hover:bg-[none] py-2" title="Login" onClick={() => navigate("/login")} />
            </div>
        </div>
    </div>
    {/* <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                <div className="text-center">
                    <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                        Transforme seu futuro financeiro
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-gray-300">
                        Aprenda, planeje e alcance seus objetivos financeiros com insights baseados em inteligência artificial, ferramentas interativas e aulas com especialistas.
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <a
                            href={config.payment.kiwify_checkout_url}
                            target="_blank"
                            className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                        >
                            Assine Agora
                        </a>
                        <a href="login" className="text-sm font-semibold leading-6 text-white">
                            Login <span aria-hidden="true">→</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="h-12 mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            </div>
        </div> */}
}