import { useQuery } from "react-query";
import { EvaluateStockBody } from "../models/types";
import { evaluateStock } from "../services/StockEvaluationService";
import { QueryKey } from "./state";

export const useStockEvaluation = (body: EvaluateStockBody | null) => {
    return useQuery([QueryKey.stockEvaluation, body], async () => {
        if (!body) return null;
        const { data } = await evaluateStock(body);
        return data;
    });
}