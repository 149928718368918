import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCourses } from "../hooks/useCourses";
import { coursesColumns } from "../pages/shared/CoursesTable";
import CourseCreationModal from "./modals/CourseCreationModal";
import BaseTable from "./monthly-budget/Table";

export default function CoursesAdmin() {
    const [selectedCourse, setSelectedCourse] = useState<null | string>(null);
    const [openCourse, setOpenCourse] = useState<boolean>(false);
    const navigate = useNavigate();
    return <>
        <CourseCreationModal courseId={selectedCourse} onClose={() => setOpenCourse(false)} open={openCourse} />
        <BaseTable
            title={null}
            onRowClick={(row) => {
                navigate(`/admin/course/${row.id}`)
            }}
            columns={coursesColumns}
            useGetData={useCourses}
            onAdd={() => {
                setOpenCourse(true)
                setSelectedCourse(null);
            }}
            onAddTitle="Curso"
        />
    </>
}