import { TransactionCategory } from "../models/types"

export const ErrorBadge = ({ title }: { title: string }) => {
    return <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-red-900 ring-1 ring-inset ring-gray-200">
        <svg className="h-1.5 w-1.5 fill-red-500" viewBox="0 0 6 6" aria-hidden="true">
            <circle cx={3} cy={3} r={3} />
        </svg>
        {title}
    </span>
}

export const SuccessBadge = ({ title }: { title: string }) => {
    return <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-green-900 ring-1 ring-inset ring-gray-200">
        <svg className="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
            <circle cx={3} cy={3} r={3} />
        </svg>
        {title}
    </span>
}

export const BaseBadge = ({ title, color }: { title: string; color?: string; }) => {
    return <div className={`max-w-fit rounded-md px-2 py-1 items-center flex text-xs font-medium ${color ? color : "text-indigo-900"} ring-1 ring-gray-200`}>
        {title}
    </div>
}

export const formatCategoryTitle = (category: TransactionCategory) => {
    if (category.category_type === "expense") {
        return "Despesa";
    } else if (category.category_type === "investment") {
        return "Investimento";
    } else {
        return "Receita";
    }
}

export const CategoryBadge = ({ category }: { category: TransactionCategory }) => {
    const categoryType = category.category_type;
    const color = categoryType === "expense" ? "red" : categoryType === "investment" ? "indigo" : "green";
    return (<svg className={`h-1.5 w-1.5 fill-${color}-500`} viewBox="0 0 6 6" aria-hidden="true">
        <circle cx={3} cy={3} r={3} />
    </svg>)
}

export const BudgetSettingBadge = ({ percentage }: { percentage: number }) => {
    return <span className="flex items-center gap-x-1.5 w-14 justify-center text-center rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-indigo-200">
        {percentage}%
    </span>
}