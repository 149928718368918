import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCourseModules } from "../hooks/useCourseModules";
import { moduleColumns } from "../pages/shared/CoursesTable";
import CourseModuleCreationModal from "./modals/CourseModuleCreationModal";
import BaseTable from "./monthly-budget/Table";

type CourseModulesTableProps = {
    courseId: string;
}

export default function CourseModulesTable({ courseId }: CourseModulesTableProps) {
    const [openCreateModule, setOpenCreateModule] = useState<boolean>(false);
    const navigate = useNavigate();
    return <div className="mt-5">
        <CourseModuleCreationModal courseId={courseId} open={openCreateModule} onClose={() => setOpenCreateModule(false)} moduleId={null} />
        <BaseTable
            title={<h3 className="text-base font-semibold leading-6 text-gray-900">Módulos</h3>}
            onRowClick={(row) => {
                navigate(`/admin/course/${row.course}/module/${row.id}`)
            }}
            columns={moduleColumns}
            params={{ course: courseId }}
            useGetData={useCourseModules}
            onAdd={() => {
                setOpenCreateModule(true)
            }}
            onAddTitle="Módulo"
        />
    </div>
}